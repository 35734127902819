import React from 'react';
import { ThemeProvider, theme } from './providers';
import { CssBaseline } from '@mui/material';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { AppRoutes } from './AppRoutes';
import { googleSSOClient } from './config';

export const App = () => {
  return (
    <GoogleOAuthProvider clientId={googleSSOClient}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AppRoutes />
      </ThemeProvider>
    </GoogleOAuthProvider>
  );
};
