import React from 'react';
import Typography from '@mui/material/Typography';
import { useStyles } from '../providers';
import { Grid, Stack, Divider } from '@mui/material';

export const PrivacyPolicyPage = () => {
  const classes = useStyles();
  return (
    <Stack spacing={4} style={{ margin: '10%' }}>
      <Grid>
        <h5
          style={{
            color: 'black',
            fontSize: 32,
            textDecoration: 'underline',
            margin: 0
          }}
        >
          Life Lessons Privacy Policy
        </h5>
      </Grid>
      <Grid>
        <Divider />
        <Typography paragraph sx={{ margin: '30px 0 0 0', textAlign: 'left' }}>
          Last Updated: November 14th, 2023
        </Typography>
        <h4>Introduction and Scope of this Policy:</h4>
        <Typography paragraph sx={{ margin: '30px 0 0 0', textAlign: 'left' }}>
          The Life Lessons platform is a web and mobile-based platform designed
          to help educators support teachers and students with SEL. The Life
          Lessons platform is operated by Life Lessons Education Inc. (“Life
          Lessons,” “we,” “us”). We are committed to protecting the privacy of
          your personal information, and we have provided this policy to our
          users (collectively, “you,” “your”), including our education
          institution customers and their employees (collectively, “Customers”)
          and students of the types of information we collect, and the ways in
          which we will use and disclose it. By accessing or using the Life
          Lessons platform, you agree to the terms of this Privacy Policy. Life
          Lessons operates from the United States and its services are intended
          for use in the United States. We do not market our services to
          residents or carry out operations in regions outside of the US,
          including, but not limited to the European Economic Area. If you are
          located outside the United States, the information you provide to us
          is transmitted and processed in the United States, and it will be
          protected subject to this Privacy Policy and applicable laws, which
          may not be as protective as the laws in your country. By using Life
          Lessons, you agree to this.
        </Typography>
        <h4>Information Customers Provide:</h4>
        <Typography paragraph sx={{ margin: '30px 0 0 0', textAlign: 'left' }}>
          When Customers decide to use the Life Lessons platform, they provide
          us with an email, first and last name for each staff member who will
          have a Life Lessons platform account. We use this information to
          create the accounts and provide initial account credentials. Customers
          will provide additional, optional information about their staff
          members, such as names of the courses they teach and their scheduling
          options. In addition, Customers may share comments on our
          instructional materials within the Life Lessons platform, and these
          comments will be visible to all Customers. Customers may also provide
          us with information when they contact us with questions, for
          troubleshooting or other customer service-related requests.
        </Typography>
        <h4>Student Data:</h4>
        <Typography paragraph sx={{ margin: '30px 0 0 0', textAlign: 'left' }}>
          Customers provide minimally required information about their students,
          such as student first and last name and ID#, which is used to ensure
          that Customers are able to view and monitor student activity within
          Life Lessons. Customers may choose to upload additional student
          information onto the Life Lessons platform, such as demographic,
          assessment, schedule, and instructional data, as well as comments on
          their student records. This information is used only for our
          Customers’ internal educational purposes. We provide Customers of Life
          Lessons with a username and password for each of their student users.
          When a student logs in, they are required to provide a new password.
          Within Outpost, students may submit text or audio answers to questions
          as they progress through the Life Lessons' learning modules. We treat
          this information as personal information under all applicable laws.
          This information is used for the Customer’s educational purposes, to
          allow them to review their students’ progress and understanding of the
          lessons.
        </Typography>
        <h4>Family Educational Rights and Privacy Act (FERPA):</h4>
        <Typography paragraph sx={{ margin: '30px 0 0 0', textAlign: 'left' }}>
          As part of our written agreements with Customers, each Customer agrees
          to appoint Life Lessons as a “school official” as the term is used in
          FERPA, 34 C.F.R. §99 et. seq. Life Lessons agrees to be bound by the
          relevant provisions of FERPA, including that we will remain under the
          “direct control” of our Customers with respect to the use and handling
          of “education records” as the term is used in FERPA. Life Lessons will
          use student personal information only in connection with providing
          services to the Customer and will only share student personal
          information with its third-party partners as necessary in connection
          with the performance of services.
        </Typography>
        <h4>Students Under Age 13:</h4>
        <Typography paragraph sx={{ margin: '30px 0 0 0', textAlign: 'left' }}>
          Under the direction of the Customer, students under age 13 may use
          Outpost. In doing so, as noted above, students may provide responses
          to questions about the lessons in open text fields. Life Lessons
          operates Outpost in compliance with the Children’s Online Privacy
          Protection Act (“COPPA”). In accordance with our Terms of Use and
          consistent with applicable law, our Customers agree to obtain any
          necessary parental consent prior to permitting students to access and
          use Outpost. We do not require that students provide more information
          than is necessary to operate Outpost, and we use student personal
          information only to provide Outpost to our Customers. Customers may
          make the personal information provided by students available to
          parents or legal guardians for their review through the Customer
          dashboard available within Life Lessons, and we will support Customers
          in facilitating that access at their request.
        </Typography>
        <h4>Information We Collect Automatically:</h4>
        <Typography paragraph sx={{ margin: '30px 0 0 0', textAlign: 'left' }}>
          When you use Life Lessons, our servers automatically record
          information sent from your browser (“Log Data”). Log Data may include
          information such as your computer’s Internet Protocol (“IP”) address,
          browser type and machine model, pages of Life Lessons that you visit,
          the time spent on those pages, activity you engage in on the pages,
          the referring URL, and access times and dates. We use this information
          to monitor, analyze use of and administer Life Lessons, and to better
          tailor Life Lessons to your needs. To collect this information, we use
          certain technologies including:
        </Typography>
        <h4>Cookies:</h4>
        <Typography paragraph sx={{ margin: '30px 0 0 0', textAlign: 'left' }}>
          A cookie is a small data file sent from a website or application and
          stored on your computer or device. Cookies allow us to recognize your
          browser when you return to our platform, and to remember your login
          information. Cookies also allow us to serve certain features, to
          better understand how you interact with Life Lessons, and to monitor
          aggregated usage. You can set your browser to detect some cookies, to
          stop accepting cookies or to prompt you before accepting a cookie. To
          learn more about browser cookies, including how to manage or delete
          them, look in the Tools, Help or similar section of your web browser,
          or visit https://allaboutcookies.org.
        </Typography>
        <h4>Analytics:</h4>
        <Typography paragraph sx={{ margin: '30px 0 0 0', textAlign: 'left' }}>
          We use third party service providers, including Google Analytics,
          Intercom, and New Relic, to assist us in collecting and understanding
          Log Data. For more information about Google Analytics, see the Google
          Analytics Terms of Use and the Google Privacy Policy. You can prevent
          Google Analytics from collecting information about you and recognizing
          you on return visits to our Platform by disabling cookies on your
          browser or by installing the Google Analytics opt out plug in. Note
          that we are not responsible for the Google Analytics opt-out tools. By
          using the Life Lessons platform, you agree to our use of these
          tracking technologies.
        </Typography>
        <h4>How We May Use Your Information:</h4>
        <Typography paragraph sx={{ margin: '30px 0 0 0', textAlign: 'left' }}>
          The Customer retains control of all student personal information that
          Life Lessons maintains. At no point is Life Lessons the owner of such
          data, nor at any point does Life Lessons control such data. We do not
          sell or rent personal information. We use and disclose personal
          information only for the purposes of providing the Life Lessons
          platform to our Customers as described above, and as follows: As
          directed by our Customers to provide them with Life Lessons platform
          features and services To our third-party service partners to permit
          them to provide features and services on our behalf (see Third Party
          Service Providers section below); To investigate a suspected violation
          of the Terms of Service, suspected fraud or other unlawful activity;
          As may be required by law or as ordered by a court, where legally
          permissible, to notify Customers and to work with Customers to seek to
          limit the scope of the required disclosure, as further described
          below; In the event of a reorganization, merger, sale, bankruptcy or
          other disposition of our business, in which case the transferred
          information will remain subject to the terms of this Privacy Policy;
          and With your consent and as permissible under applicable laws and
          regulations. In the event that we receive a court order or law
          enforcement request (including but not limited to an administrative
          subpoena or judicial warrant) for access to, use of, or inspection of
          Customer data, including Customer’s student personal information,
          wherever legally permissible, we will not act directly on such
          request, but will instead promptly refer such requests directly to
          Customer. To the extent we are legally required to provide the
          information requested prior to or in addition to such referral,
          wherever legally permissible we will promptly notify the Customer of
          the request and of Life Lessons' intent to comply with the request.
        </Typography>
        <h4>Third Party Service Providers:</h4>
        <Typography paragraph sx={{ margin: '30px 0 0 0', textAlign: 'left' }}>
          We employ third parties to facilitate delivery of the Life Lessons
          platform and to provide certain features on our behalf, such as
          maintenance services, database management, and customer support. These
          parties are provided with the minimally required information necessary
          for them to provide these features and services. Except as
          specifically described in this Privacy Policy, these third parties do
          not have access to personally identifiable information. All third
          parties engaged by Life Lessons to support the Life Lessons platform
          have agreed to handle the information in in a manner that is
          consistent with this Privacy Policy.
        </Typography>
        <h4>How You Can Access and Modify Your Information:</h4>
        <Typography paragraph sx={{ margin: '30px 0 0 0', textAlign: 'left' }}>
          Customers may review, correct, update, or request that we delete
          personal information they or their students have provided to us by
          accessing the dashboard within Life Lessons or, in some situations, by
          contacting Life Lessons to affect the requesting change. In order for
          such updates to remain in effect, Customers must make corresponding
          updates in data files that they choose to upload to Life Lessons after
          they have made updates within the platform. Since Life Lessons is used
          under the direction of our Customers, parents, guardians, and eligible
          students must work directly with their school or district to review or
          modify information in their education record that may be stored on
          Life Lessons.
        </Typography>
        <h4>Data Retention:</h4>
        <Typography paragraph sx={{ margin: '30px 0 0 0', textAlign: 'left' }}>
          Prior to expiration or termination of the Customer’s contract with us
          and upon written request from Customer, Life Lessons will provide
          Customer with a one-time, delimited file export of its personal
          student information from the Services. Within 90 days of termination,
          or within 30 days of receipt of a written request from Customer, Life
          Lessons will securely destroy Customer’s student and staff member
          personal information. We may retain de-identified information,
          including aggregated, de-identified data for a variety of purposes
          subject to applicable law, including: To develop and improve our
          educational products for adaptive learning purposes and for
          customizing the student experience; To demonstrate the effectiveness
          of Life Lessons, including in our marketing materials; and To develop
          and improve our educational products.
        </Typography>
        <h4>Security:</h4>
        <Typography paragraph sx={{ margin: '30px 0 0 0', textAlign: 'left' }}>
          We take commercially reasonable and appropriate technical,
          organizational, and physical security measures to maintain the
          confidentiality, security, and integrity of the data entrusted to Life
          Lessons and to help prevent unauthorized access to our Customer data.
          We store data in secure server and cloud-based environments that use
          firewalls, VPNs, event logging, and other industry-standard
          protections in an effort to monitor and prevent access from outside
          intruders. We also encrypt the data in transit, require unique account
          credentials, and limit data access. Not all security risks are
          reasonably foreseeable, however, and Life Lessons is not responsible
          for security incidents that are not reasonably foreseeable and not
          reasonably within its control.
        </Typography>
        <h4>Breach Notification:</h4>
        <Typography paragraph sx={{ margin: '30px 0 0 0', textAlign: 'left' }}>
          Life Lessons follows documented “Security Incident Management
          Procedures” when investigating any potential security incident. In the
          event of a data security breach, Life Lessons will notify impacted
          customers promptly that a breach has occurred, and will inform them
          (to the extent known) what data has been compromised. Life Lessons
          expects customers to notify individual teachers and parents of any
          such breach to the extent required, but will provide customers
          reasonably requested assistance with such notifications and will also
          reimburse customers for the reasonable costs associated with legally
          required breach notices.
        </Typography>
        <h4>Updates To This Privacy Policy:</h4>
        <Typography paragraph sx={{ margin: '30px 0 0 0', textAlign: 'left' }}>
          We review this Privacy Policy at least annually and may change this
          Privacy Policy from time to time in the future, for example,to reflect
          changes in legal requirements or our products and corresponding
          practices, and to provide more clarity to our customers on our
          practices. When we do, we will post a notice within the product and
          provide other notifications as required by law. If we make material
          changes to the Privacy Policy, we will notify our primary Customer
          contact via the email address we have in our records and changes will
          become effective in thirty days after the “last updated” date at the
          top of this policy. Customers with concerns on any material changes
          should reach out to their account representative to have them
          addressed within those thirty days. Non-material changes will become
          effective when posted in Life Lessons. The “last updated” date at the
          top of this policy indicates when it was last revised.
        </Typography>
        <h4>Contact Information:</h4>
        <Typography paragraph sx={{ margin: '30px 0 0 0', textAlign: 'left' }}>
          For additional information regarding our privacy practices, please
          contact us at: luke@lifelessonsed.com
        </Typography>
        <h4>Notice for California & Nevada Residents:</h4>
        <Typography paragraph sx={{ margin: '30px 0 0 0', textAlign: 'left' }}>
          Life Lessons is a Service Provider under the California Consumer
          Privacy Act (CCPA). As a California resident, you have certain rights
          regarding your personal information. These rights include: Right to
          Know and Access Information: You may request information about the
          personal information we maintain about you in the ordinary course of
          business. This may include what personal information we collect, use,
          or disclose about you. Right to Deletion: You may request your
          personal information be deleted. As noted below, in the event you
          choose to exercise your rights under the CCPA, you may contact the
          institution to which you have provided your personal information. Life
          Lessons refers all requests directed to it to the appropriate
          Customer. In order to prevent unauthorized access to your information,
          we are required by law to verify your identity before we may address
          your request. Since we will facilitate your requests through your
          education institution, we will rely on their verification of your
          identity and our existing Customer contact information in order to
          process requests. While Life Lessons will fulfill requests made under
          the CCPA, we may not fulfill some or all of your requests to access or
          delete information as permitted by applicable law. We do not sell your
          personal information. In the event we fulfill a request to delete your
          personal information, such personal information may still remain on
          Life Lessons' backup systems until such time as the information is
          deleted in accordance with Life Lessons' data retention schedule. Such
          backup information is not ordinarily accessible by Life Lessons
          personnel.
        </Typography>
        <h4>To Exercise Your Rights:</h4>
        <Typography paragraph sx={{ margin: '30px 0 0 0', textAlign: 'left' }}>
          Life Lessons is used at the direction of our Customers. In addition,
          we are obligated under FERPA to remain under the direct control of our
          Customers with respect to our use and maintenance of student personal
          information that is part of the education record. As such, to exercise
          your rights as described in this section, please contact your
          education institution and we will work with them to facilitate your
          request. Your exercise of the above rights is subject to certain
          exemptions to safeguard the public interest and our interests.
          Requests to exercise these rights may be granted in whole, in part, or
          not at all, depending on the scope and nature of the request and
          applicable law. Where required by applicable law, we will notify you
          if and why we are unable to fulfill your request. Authorized Agent:
          California residents may use an authorized agent on their behalf to
          exercise a privacy right discussed above. If you are an authorized
          agent acting on behalf of a California resident to communicate with us
          or to exercise a privacy right discussed above, you must be able to
          demonstrate that you have the requisite authorization to act on behalf
          of the resident and have sufficient access to their laptop, desktop,
          or mobile device to exercise these rights digitally. If you are an
          authorized agent trying to exercise rights on behalf of a Life Lessons
          user, please contact the user's school or district with supporting
          verification information, which includes a valid Power of Attorney in
          the State of California, proof that you have access to the user’s
          device, and proof of your own identity. Non-discrimination: We shall
          not discriminate or otherwise penalize anyone for exercising their
          rights. Categories of Personal information we collect: Identifiers
          such as a real name, unique personal identifier, online identifier,
          Internet Protocol address, email address, account name; your name is
          also considered personal information under subdivision (e) of
          California Business and Professions Code Section 1798.80 Internet or
          other electronic network activity information regarding your
          interaction with Life Lessons Geolocation information in the form of
          your school address Education information, defined as information that
          is not publicly available personally identifiable information as
          defined in the Family Educational Rights and Privacy Act (20 U.S.C.
          section 1232g, 34 C.F.R. Part 99). Categories of sources from which
          the Personal Information is collected We collect the Personal
          Information directly from the Customer or in limited circumstances,
          from student users. Business or commercial purpose for collecting or
          selling Personal Information We collect your Personal Information to
          provide the services and for the following business purposes:
          Performing services in accordance with our contract with the Customer
          and the terms of use including maintaining or servicing accounts,
          providing customer service, processing or fulfilling orders and
          transactions, verifying customer information, and processing payments.
          Detecting security incidents, protecting against malicious, deceptive,
          fraudulent, or illegal activity, and prosecuting those responsible for
          that activity. Debugging to identify and repair errors that impair
          existing intended functionality. Sending you product communications.
          Categories of third parties with whom we share Personal Information We
          share your personal information only with service providers who
          support us in delivering the Services as described above. We do not
          share your Personal Information with "third parties" as the term is
          defined in CCPA. That is, we do not share your Personal Information
          with individuals or organizations to use for their own commercial
          purposes. Specific pieces of Personal Information we have collected
          Customer information, including ID number, first and last name, and
          email address for their employee users, and student first and last
          names and ID numbers, as well as IP address for all users. Customers
          may provide additional information about their students, such as
          demographic, assessment, schedule, and instructional data, as well as
          comments on their student records. When using Outpost, Students also
          provide passwords and text or audio responses to questions in the
          learning modules. Nevada Residents: We are required under Nevada law
          to notify you that we do not sell consumer personal information.
        </Typography>
      </Grid>
    </Stack>
  );
};
