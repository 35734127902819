import { Route, Routes } from 'react-router-dom';
import React from 'react';
import { CommonLayout } from './components';
import {
  CASELPage,
  HowToPlayPage,
  HomePage,
  Login,
  SignUp,
  ResetPassword,
  ForgotPasswordSubmit,
  ForgotPassword,
  PrivacyPolicyPage,
  TermsPage,
  ProgramPage,
  UnitPage,
  ActivityPage,
  GameHistory,
  SurveyHistoryPage,
  ClassroomResults,
  SurveyResultsPage,
  PlayerResults,
  PlayerHistory,
  LessonHistory,
  LessonSummaryPage,
  MatchSummaryPage
} from './views';

export const AppRoutes = () => {
  return (
    <Routes>
      <Route element={<ResetPassword />} path='/resetPassword/:resetToken' />
      <Route element={<ForgotPasswordSubmit />} path='/forgotPasswordSubmit' />
      <Route element={<ForgotPassword />} path='/forgotPassword' />
      <Route element={<Login />} path='/' />
      <Route element={<PrivacyPolicyPage />} path='/privacyPolicy' />
      <Route element={<TermsPage />} path='/tos' />
      <Route element={<SignUp />} path='/signup' />
      <Route
        element={
          <CommonLayout>
            <ProgramPage />
          </CommonLayout>
        }
        path='/program'
      />
      <Route
        element={
          <CommonLayout>
            <HomePage />
          </CommonLayout>
        }
        path='/home'
      />
      <Route
        element={
          <CommonLayout>
            <HowToPlayPage />
          </CommonLayout>
        }
        path='/howtoplay'
      />
      <Route
        element={
          <CommonLayout>
            <CASELPage />
          </CommonLayout>
        }
        path='/casel'
      />
      <Route
        element={
          <CommonLayout>
            <GameHistory />
          </CommonLayout>
        }
        path='/gameHistory'
      />
      <Route
        element={
          <CommonLayout>
            <SurveyHistoryPage />
          </CommonLayout>
        }
        path='/surveyHistory'
      />
      <Route
        element={
          <CommonLayout>
            <LessonHistory />
          </CommonLayout>
        }
        path='/lessonHistory'
      />
      <Route
        element={
          <CommonLayout>
            <UnitPage />
          </CommonLayout>
        }
        path='/unit/:unitId'
      />
      <Route
        element={
          <CommonLayout>
            <ActivityPage />
          </CommonLayout>
        }
        path='/unit/:unitId/activity/:activityId'
      />
      <Route
        element={
          <CommonLayout>
            <LessonSummaryPage />
          </CommonLayout>
        }
        path='/lessonSummary/:id'
      />
      <Route
        element={
          <CommonLayout>
            <PlayerResults />
          </CommonLayout>
        }
        path='/playerResults/:gameId'
      />
      <Route
        element={
          <CommonLayout>
            <SurveyResultsPage />
          </CommonLayout>
        }
        path='/survey/:id'
      />
      <Route
        element={
          <CommonLayout>
            <ClassroomResults />
          </CommonLayout>
        }
        path='/classroomSurvey/:date'
      />
      <Route
        element={
          <CommonLayout>
            <PlayerHistory />
          </CommonLayout>
        }
        path='/playerHistory/:id'
      />
      <Route
        element={
          <CommonLayout>
            <MatchSummaryPage />
          </CommonLayout>
        }
        path='/match/:id/summary'
      />
    </Routes>
  );
};
