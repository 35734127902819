import React, { useState } from 'react';
import { Box } from '@mui/system';
import { Button, Grid, Stack, TextField } from '@mui/material';
import { useNavigate } from 'react-router';
import { AuthProvider } from '../providers/AuthProvider';
import logo from '../assets/logo.png';
import { Link } from 'react-router-dom';
import { CustomError } from '../errorType';

export const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>('');
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<CustomError | null>(null);
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const validateInput = (inputValue: string) => {
    // Regular expression for email validation
    if (inputValue.trim() === '') {
      setError(true);
      setErrorMessage({ code: 400, detail: 'This field is required.' });
    } else if (!emailRegex.test(inputValue.trim())) {
      setError(true);
      setErrorMessage({ code: 400, detail: 'Valid email required' });
    } else {
      setError(false);
      setErrorMessage(null);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (emailRegex.test(email.trim())) {
      try {
        const data = await AuthProvider.forgotPassword(email);

        if (data) {
          console.log(data);
          navigate('/forgotPasswordSubmit');
        }
      } catch (err) {
        if (err && typeof err === 'object' && 'detail' in err) {
          const customError = err as CustomError;
          setError(true);
          setErrorMessage(customError);
        } else {
          setError(true);
          setErrorMessage({
            code: 404,
            detail: 'User not found. Please use a valid email.'
          });
        }
      }
    } else {
      setError(true);
      setErrorMessage({
        code: 404,
        detail: 'Invalid email. Please use a valid email.'
      });
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        textAlign: 'center'
      }}
    >
      <Box
        sx={{
          backgroundColor: 'white',
          borderRadius: 3,
          border: 'solid #E0E0E0',
          borderWidth: '1px',
          minWidth: 375,
          width: '30%',
          height: 'auto',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center'
        }}
      >
        <form
          onSubmit={handleSubmit}
          style={{
            width: '80%'
          }}
        >
          <Stack
            spacing={4}
            sx={{
              display: 'flex',
              width: '100%',
              margin: 'auto',
              paddingTop: 5,
              paddingBottom: 5,
              justifyContent: 'center'
            }}
          >
            <div>
              <img
                src={logo}
                style={{
                  justifyContent: 'center',
                  height: '150px',
                  width: '150px'
                }}
              ></img>
              <h5
                style={{
                  color: '#1565c0',
                  margin: 'auto',
                  fontSize: 32,
                  textAlign: 'center'
                }}
              >
                Reset Password
              </h5>
            </div>

            <Grid style={{ width: '100%' }}>
              <TextField
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setEmail(e.currentTarget.value);
                  validateInput(e.currentTarget.value);
                }}
                id='email'
                label='Email'
                value={email}
                type='email'
                required={true}
                style={{ width: '100%' }}
                autoFocus={true}
                error={error}
                helperText={errorMessage?.detail}
              />
            </Grid>

            <Grid
              container
              spacing={1}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Grid item xs={12}>
                <Button
                  type='submit'
                  variant='contained'
                  onClick={(e: React.FormEvent) => handleSubmit(e)}
                >
                  Send Reset Email
                </Button>
              </Grid>
              <Grid>
                <div style={{ color: '#1d1d1d', fontSize: 16 }}>
                  <p>
                    <Link to='/'>Return to Log In</Link>
                  </p>
                </div>
              </Grid>
            </Grid>
          </Stack>
        </form>
      </Box>
    </div>
  );
};
