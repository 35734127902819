import React, { useEffect, useRef } from 'react';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { AuthProvider, theme } from '../providers';
import { useParams, Link, useNavigate } from 'react-router-dom';
import {
  Box,
  Grid,
  Stack,
  Divider,
  ListItem,
  ListItemText,
  List,
  Button
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Activities } from '../config';
import { useReactToPrint } from 'react-to-print';

const useStyles = makeStyles(() => ({
  activityContent: {
    width: '90%',
    padding: '16px',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '85%',
      margin: '0 0 0 10%'
    }
  }
}));

export const ActivityPage: React.FC = () => {
  const ref = useRef<HTMLDivElement>(null);
  const classes = useStyles();
  const { unitId } = useParams<{ unitId?: string }>();
  const { activityId } = useParams<{ activityId?: string }>();
  const selectedActivity =
    Activities[unitId as keyof typeof Activities][
      activityId as keyof typeof Activities
    ];
  const isAuthenticated = AuthProvider.isAuthenticated();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/');
    }
  }, [isAuthenticated, navigate]);

  const handlePrint = useReactToPrint({
    content: () => ref.current,
    pageStyle: `
    @page { 
      size: auto; 
      margin: 200mm; 
    }
    @media print {
      .print-hidden {
        display: none !important;
      }
    }
  `
  });

  return (
    <Box ref={ref} className={classes.activityContent}>
      <Stack
        spacing={4}
        sx={{
          width: '75vw',
          justifyContent: 'center',
          overflow: 'visible',
          height: 'auto'
        }}
      >
        <Link
          to={`/unit/${unitId}`}
          className='print-hidden'
          style={{
            textDecoration: 'none',
            cursor: 'pointer',
            display: 'block',
            margin: 0
          }}
        >
          <ArrowBackIcon sx={{ fontSize: 'medium', paddingTop: '5px' }} />
          <Typography sx={{ display: 'inline-block' }}>Back to Unit</Typography>
        </Link>
        <Grid container direction='row' spacing={2}>
          <Grid item xs={12} sm={9} style={{ padding: 0 }}>
            <h4
              style={{
                color: '#3a5169',
                fontSize: 34,
                textDecoration: 'underline',
                margin: 0,
                textAlign: 'left'
              }}
            >
              {selectedActivity.title} Activity
            </h4>
          </Grid>
          <Grid item xs={12} sm={3} style={{ padding: '5px 0 0 0' }}>
            <Button
              variant='contained'
              color='primary'
              className='print-hidden'
              onClick={handlePrint}
              style={{
                backgroundColor: '#3a5169',
                fontSize: 16,
                margin: '0 10px 0 0',
                minWidth: '100px'
              }}
            >
              Print Activity
            </Button>
          </Grid>
        </Grid>
        <Grid style={{ display: 'inherit', marginTop: '16px' }}>
          <h5
            style={{
              color: '#3a5169',
              fontSize: 20,
              textDecoration: 'underline',
              margin: 0,
              padding: '0 0 10px 0',
              textAlign: 'left'
            }}
          >
            Total Time:
          </h5>
          <Typography
            paragraph
            sx={{ textAlign: 'left', margin: '1px 10px', fontSize: '18px' }}
          >
            {selectedActivity.time} minutes
          </Typography>
        </Grid>
        <Grid style={{ marginTop: '16px' }}>
          <h5
            style={{
              color: '#3a5169',
              fontSize: 20,
              textDecoration: 'underline',
              margin: 0,
              padding: '0 0 10px 0',
              textAlign: 'left'
            }}
          >
            Objective
          </h5>
          <Typography paragraph sx={{ textAlign: 'left' }}>
            {selectedActivity.objective}
          </Typography>
        </Grid>
        <Divider style={{ margin: '10px 0 10px 0' }} />
        <Grid style={{ marginTop: '16px' }}>
          <h5
            style={{
              color: '#3a5169',
              fontSize: 20,
              textDecoration: 'underline',
              margin: 0,
              padding: '0 0 10px 0',
              textAlign: 'left'
            }}
          >
            Background
          </h5>
          <Typography paragraph sx={{ textAlign: 'left' }}>
            {selectedActivity.background}
          </Typography>
        </Grid>
        <Divider style={{ margin: '10px 0 10px 0' }} />
        <Grid style={{ marginTop: '16px' }}>
          <h5
            style={{
              color: '#3a5169',
              fontSize: 20,
              textDecoration: 'underline',
              margin: 0,
              padding: '0 0 10px 0',
              textAlign: 'left'
            }}
          >
            Materials:
          </h5>
          {
            <List
              sx={{
                fontSize: '20px',
                padding: '0 0 0 30px',
                margin: '1px',
                listStyleType: 'disc'
              }}
            >
              {selectedActivity.materials.map((material, index) => (
                <ListItem
                  key={index}
                  sx={{
                    fontSize: '20px',
                    padding: '0 0 0 10px',
                    margin: '1px',
                    display: 'list-item'
                  }}
                >
                  <ListItemText primary={material} />
                </ListItem>
              ))}
            </List>
          }
        </Grid>
        <Divider style={{ margin: '10px 0 10px 0' }} />
        <Grid style={{ marginTop: '16px', paddingBottom: '100px' }}>
          <h5
            style={{
              color: '#3a5169',
              fontSize: 20,
              textDecoration: 'underline',
              margin: 0,
              padding: '0 0 10px 0',
              textAlign: 'left'
            }}
          >
            Steps:
          </h5>
          {selectedActivity.steps.map((step, index) => (
            <div key={index}>
              <h6
                style={{
                  color: '#3a5169',
                  fontSize: '18px',
                  textDecoration: 'underline',
                  margin: 0,
                  padding: '0 0 10px 20px',
                  textAlign: 'left'
                }}
              >
                {step.title}
              </h6>
              <List
                sx={{
                  fontSize: '16px',
                  padding: '0 0 10px 30px',
                  margin: '1px',
                  listStyleType: 'decimal'
                }}
              >
                {step.bullets.map((bullet, bulletIndex) => (
                  <ListItem
                    key={bulletIndex}
                    sx={{
                      fontSize: '16px',
                      padding: '0 0 10px 10px',
                      margin: '1px 0 0 30px',
                      display: 'list-item'
                    }}
                  >
                    <ListItemText primary={bullet} />
                  </ListItem>
                ))}
              </List>
            </div>
          ))}
        </Grid>
      </Stack>
    </Box>
  );
};
