import React, { useEffect, useRef, useState } from 'react';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { AuthProvider, theme, LessonProvider } from '../providers';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import {
  Box,
  Grid,
  Stack,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useReactToPrint } from 'react-to-print';
import { DataObject } from '@mui/icons-material';

const useStyles = makeStyles(() => ({
  summaryContent: {
    width: '90%',
    padding: '16px',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '85%',
      margin: '0 0 0 10%'
    }
  }
}));

interface DataObject {
  question_id: number;
  question: string;
  answer: string;
  answer_type: string;
}

interface LessonSummary {
  lesson_instance_id: number;
  lesson_summary: string;
  timestamp: string;
  casel_type: string;
  lesson_id: number;
  user_id: number;
  question_answer_pairs: Array<DataObject>;
}

export const LessonSummaryPage: React.FC = () => {
  const [data, setData] = useState<LessonSummary>();
  const ref = useRef<HTMLDivElement>(null);
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const isAuthenticated = AuthProvider.isAuthenticated();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const multiplayer = (
    searchParams.get('multiplayer') !== null
      ? searchParams.get('multiplayer') === 'true'
      : 'false'
  ) as string;

  useEffect(() => {
    if (id) {
      fetchData(parseInt(id));
    }
  }, [id]);

  const fetchData = async (id: number) => {
    try {
      const data = await LessonProvider.getLessonSummary(id, multiplayer);

      setData(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/');
    }
  }, [isAuthenticated, navigate]);

  const handlePrint = useReactToPrint({
    content: () => ref.current,
    pageStyle: `
    @page { 
      size: auto; 
      margin: 200mm; 
    }
    @media print {
      .print-hidden {
        display: none !important;
      }
    }
  `
  });

  return (
    <Box ref={ref} className={classes.summaryContent}>
      <Stack
        spacing={4}
        sx={{
          width: '75vw',
          justifyContent: 'center',
          overflow: 'visible',
          height: 'auto'
        }}
      >
        <div
          onClick={() => navigate(-1)}
          className='print-hidden'
          style={{
            textDecoration: 'none',
            cursor: 'pointer',
            display: 'block',
            margin: 0
          }}
        >
          <ArrowBackIcon sx={{ fontSize: 'medium', paddingTop: '5px' }} />
          <Typography sx={{ display: 'inline-block' }}>
            Back to Previous Page
          </Typography>
        </div>
        <Grid container direction='row' spacing={2}>
          <Grid item xs={12} sm={9} style={{ padding: 0 }}>
            <h4
              style={{
                color: '#3a5169',
                fontSize: 34,
                margin: 0,
                textAlign: 'left'
              }}
            >
              Lesson Summary
            </h4>
          </Grid>
          <Grid item xs={12} sm={3} style={{ padding: '5px 0 0 0' }}>
            <Button
              variant='contained'
              color='primary'
              className='print-hidden'
              onClick={handlePrint}
              style={{
                backgroundColor: '#3a5169',
                fontSize: 16,
                margin: '0 10px 0 10px',
                minWidth: '100px'
              }}
            >
              Print Summary
            </Button>
          </Grid>
          <Grid style={{ display: 'inherit', marginTop: '16px' }}>
            <h5
              style={{
                color: '#3a5169',
                fontSize: 20,
                textDecoration: 'underline',
                margin: 0,
                padding: '0 0 10px 0',
                textAlign: 'left'
              }}
            >
              CASEL Competency:
            </h5>
            <Typography
              paragraph
              sx={{ textAlign: 'left', margin: '1px 10px', fontSize: '18px' }}
            >
              {data && data.casel_type}
            </Typography>
          </Grid>
        </Grid>
        {/* <Grid style={{ display: 'inherit', marginTop: '16px' }}>
          <h5
            style={{
              color: '#3a5169',
              fontSize: 20,
              textDecoration: 'underline',
              margin: 0,
              padding: '0 0 10px 0',
              textAlign: 'left'
            }}
          >
            Lesson Score:
          </h5>
          <h4
            style={{
              color: '#3a5169',
              fontSize: 18,
              margin: 0,
              padding: '1px 10px 0px 10px',
              textAlign: 'left'
            }}
          >
            4.4 / 10
          </h4>
        </Grid>
        <h4
          style={{
            color: 'grey',
            fontSize: 16,
            margin: 0,
            padding: '0 0px 10px 5px',
            textAlign: 'left',
            fontStyle: 'italic'
          }}
        >
          Evaluation criteria is based on accurately understanding and
          identifying the context of the lesson and the social or emotional
          challenge for the characters within the lesson as it relates to
          Self-Management.
        </h4> */}
        <Grid style={{ display: 'inherit', marginTop: '16px' }}>
          <h5
            style={{
              color: '#3a5169',
              fontSize: 20,
              textDecoration: 'underline',
              margin: 0,
              padding: '0 0 10px 0',
              textAlign: 'left'
            }}
          >
            Lesson Description:
          </h5>
          <Typography
            paragraph
            sx={{ textAlign: 'left', margin: '1px 10px', fontSize: '18px' }}
          >
            {data && data.lesson_summary}
          </Typography>
        </Grid>
        {data &&
          data?.question_answer_pairs.map((questionObj: DataObject, index) => (
            <Grid>
              <h5
                style={{
                  color: '#3a5169',
                  fontSize: 20,
                  margin: 0,
                  padding: '0 0 10px 0',
                  textAlign: 'left'
                }}
              >
                {index + 1}. {questionObj.question}
              </h5>
              <TextField
                label='Response'
                multiline
                rows={4}
                value={questionObj.answer}
                variant='outlined'
                InputProps={{
                  readOnly: true
                }}
                InputLabelProps={{
                  style: { color: '#3a5169' }
                }}
                style={{
                  fontSize: 20,
                  margin: '10px 0 0 20px',
                  textAlign: 'left',
                  minWidth: '70%'
                }}
              />
            </Grid>
          ))}
        <Grid item xs={12} sm={9} style={{ padding: 0 }}>
          <h5
            style={{
              color: '#3a5169',
              fontSize: 20,
              textDecoration: 'underline',
              margin: 0,
              padding: '0 0 10px 0',
              textAlign: 'left'
            }}
          >
            Follow Up Questions
          </h5>
          <List>
            <ListItem>
              <ListItemText primary='What did you learn from playing the game?' />
            </ListItem>
            <ListItem>
              <ListItemText primary='What do you think it would feel like to be in the main character’s shoes?' />
            </ListItem>
            <ListItem>
              <ListItemText primary='What emotions do you think they were feeling?' />
            </ListItem>
            <ListItem>
              <ListItemText primary='Can you think of a time when you felt that way?' />
            </ListItem>
            <ListItem>
              <ListItemText primary='How do those emotions get in the way of the main character reaching their goal?' />
            </ListItem>
            <ListItem>
              <ListItemText primary='What are some of your experiences with challenging emotions and how they can get in the way of reaching your goals.' />
            </ListItem>
            <ListItem>
              <ListItemText primary='What are some things that you suggested the main character could do to calm themself down?' />
            </ListItem>
            <ListItem>
              <ListItemText primary='Take 2 minutes to think - What do you do to calm yourself down when you experience challenging emotions?' />
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={12} sm={9} style={{ padding: 0 }}>
          <h5
            style={{
              color: '#3a5169',
              fontSize: 20,
              margin: '10px 0 10px 0',
              textAlign: 'left'
            }}
          >
            {' '}
            Review and sign below.{' '}
          </h5>
          <h5
            style={{
              color: '#3a5169',
              fontSize: 24,
              margin: 0,
              textAlign: 'left',
              display: 'inline-block'
            }}
          >
            Guardian Signature:
          </h5>
          <h5
            style={{
              color: '#3a5169',
              fontSize: 24,
              margin: '20px 0 0 0',
              display: 'inline-block'
            }}
          >
            __________________________
          </h5>
        </Grid>
      </Stack>
    </Box>
  );
};
