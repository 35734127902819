import React from 'react';
import Typography from '@mui/material/Typography';
import { useStyles } from '../providers';
import { Grid, Stack, Divider } from '@mui/material';

export const TermsPage = () => {
  const classes = useStyles();
  return (
    <Stack spacing={4} style={{ margin: '10%' }}>
      <Grid>
        <h5
          style={{
            color: 'black',
            fontSize: 32,
            textDecoration: 'underline',
            margin: 0
          }}
        >
          Life Lessons Terms and Conditions of Use
        </h5>
      </Grid>
      <Grid>
        <Divider />
        <Typography paragraph sx={{ margin: '30px 0 0 0', textAlign: 'left' }}>
          Last Updated: November 14th, 2023
        </Typography>
        <h4>Introduction and Scope of this Policy:</h4>
        <Typography paragraph sx={{ margin: '30px 0 0 0', textAlign: 'left' }}>
          These Terms and Conditions of Use (the “TOU”) apply to the digital
          products and services offered by Life Lessons Education Inc. (Life
          Lessons), including the Life Lessons® Platform, and Outpost®
          (collectively and individually, the “Services”). By using your login
          to access the Services, you agree, on behalf of your organization, to
          abide by these TOU. All references to “You” or “you” or “Customer” in
          these TOU refer to your organization, which has licensed access to the
          System (as defined below) and/or Services from Life Lessons. All
          authorized users within your organization are expected to comply with
          these TOU.
        </Typography>
        <h4>License Grant</h4>
        <Typography paragraph sx={{ margin: '30px 0 0 0', textAlign: 'left' }}>
          During the period of time specified in the applicable price quote
          agreed to by you and Life Lessons (the “Price Quote”), and subject to
          your compliance with these TOU, Life Lessons grants to Customer a
          limited, worldwide, non-exclusive, non-transferable right to access
          and use the features and functionality of the System and/or Services
          identified in the Price Quote for Customer Users (defined below), in
          the quantity specified on the applicable Price Quote, solely for your
          internal educational purposes in accordance with the terms and
          conditions expressed in these TOU. All rights not specifically granted
          in these TOU are fully reserved by Life Lessons. As used in these TOU,
          "System" means Life Lessons' proprietary, Internet-delivered SaaS
          platform of servers, software and related technology that is owned and
          operated by Life Lessons and furnished to Customer pursuant to the
          Price Quote.
        </Typography>
        <h4>Restrictions</h4>
        <Typography paragraph sx={{ margin: '30px 0 0 0', textAlign: 'left' }}>
          Customer will not, and will ensure that Customer Users do not, (a) use
          the System other than in compliance with these TOU and applicable
          federal, state, and local laws; (b) frame, distribute, resell, or
          permit access to the System by any third party; (c) interfere with the
          System or disrupt any other users’ access to the System; (d) attempt
          to gain unauthorized access to the System, or attempt to discover the
          underlying source code or structure of the System, or otherwise
          reverse engineer the System; (f) submit to the System any content or
          data that is false, misleading, defamatory or threatening; infringing
          of intellectual property rights; reasonably deemed to involve moral
          turpitude or that contains mass mailings or any form of “spam”; (g)
          submit to the System any data or code that contains a time bomb,
          virus, or any other malware that is designed to delete, disable or
          otherwise inhibit or harm any element of the System, or which is
          intended to provide unauthorized access to the System; or (h) use any
          robot, spider, data scraping or extraction tool or similar mechanism
          with respect to the System. As used in these TOU, “Customer User(s)”
          means any of Customer’s authorized users of the System, which may
          include students, teachers, administrators, or other Customer
          personnel. Customer must comply with, and ensure that its Customer
          Users comply with these TOU, as well as ensure that: (a) Customer
          provides true, accurate, current and complete information to create
          and maintain accounts; (b) neither Customer nor any Customer User
          circumvents or otherwise interferes with any user authentication or
          security mechanism used by Life Lessons; (c) Customer Users maintain
          the confidentiality of their usernames and passwords; (d) neither
          Customer nor any Customer User will impersonate another user of the
          System or provide false identity information to gain access to or use
          the System; and (e) Customer immediately notifies Life Lessons of any
          known or suspected unauthorized access to Customer or Customer User
          accounts or compromise of account credentials.
        </Typography>
        <h4>Ownership and Rights</h4>
        <Typography paragraph sx={{ margin: '30px 0 0 0', textAlign: 'left' }}>
          Customer retains all right, title and interest in (i) any data, files,
          images, and other content that Customer or a Customer User uploads or
          submits to the System pursuant to these TOU; and (ii) any reports
          produced by Customer in connection with use of the System
          (collectively, “Customer Content”). “Customer Content” does not
          include de-identified data, which Life Lessons may create using
          Customer Content on a de-identified basis (a) to develop and improve
          its products; (b) for the purposes of adaptive and customized
          learning; (c) for research and development purposes; and (d) to
          demonstrate the effectiveness of its products. You hereby grant Life
          Lessons a worldwide, royalty-free, perpetual license to use
          de-identified data for the purposes identified in the preceding
          sentence. The Services, including all trademarks, service marks,
          logos, documents, graphics, content, and/or other materials viewed or
          obtained from or through the Services (collectively, “Service
          Materials”), are owned and/or licensed by Life Lessons and are
          protected by copyright and other intellectual property rights.
          Customer has no rights to transfer, reproduce, or prepare any
          derivative works with respect to the Services, or to disclose
          confidential information pertaining to the Services. These TOU do not
          convey to Customer or any Customer User any right of ownership in or
          related to the Service or other intellectual property owned by Life
          Lessons. Customer may, at its option, provide to Life Lessons feedback
          or suggestions for enhancement concerning the System (“Feedback”), and
          Life Lessons will have a perpetual right to use and incorporate
          Feedback into the System without any compensation or other obligation
          to Customer. Customer shall not gain any right, title or interest in
          the System or Life Lessons' intellectual property as a result of its
          furnishing Feedback or Life Lessons' use of Feedback.
        </Typography>
        <h4>Data Protection</h4>
        <Typography paragraph sx={{ margin: '30px 0 0 0', textAlign: 'left' }}>
          Privacy Policy. Life Lessons takes the protection of Customer Content
          very seriously. For a full description of Life Lessons' data-handling
          practices, please review Life Lessons' Privacy Policy found at
          https://app.lifelessonsed.com/privacyPolicy, which is incorporated
          into these TOU. Life Lessons reserves the right to modify the Privacy
          Policy in accordance with the procedure outlined in the Privacy
          Policy. Family Educational Rights and Privacy Act. Customers subject
          to the Family Educational Rights and Privacy Act, 34 C.F.R. §99 et.
          seq. (“FERPA”), appoint Life Lessons a “school official” as that term
          is used in FERPA, and determine that Life Lessons has a “legitimate
          educational interest” for the purpose of carrying out its
          responsibilities under these TOU. Life Lessons shall be bound by the
          relevant provisions of FERPA, including that it will remain under the
          “direct control” of Customer with respect to its use and maintenance
          of “education records” as that term is defined in FERPA. Life Lessons
          will use personally identifiable student data only in connection with
          providing services to the Customer and will only share personally
          identifiable student data with its third-party vendors as necessary to
          provide services to the Customer. Parental Consent. If Customer
          purchases Services available for use by students, if required under
          applicable state or federal law, Customer will be responsible for
          obtaining verifiable parent consent prior to making such Services
          available to its students under the age of 13. Life Lessons shall
          comply with its responsibilities under the Children’s Online Privacy
          Protection Act (“COPPA”) and state law.
        </Typography>
        <h4>Data Security</h4>
        <Typography paragraph sx={{ margin: '30px 0 0 0', textAlign: 'left' }}>
          Life Lessons deploys security precautions intended to help maintain
          the confidentiality, integrity, and availability of Customer data
          stored by Life Lessons, including use of firewalls, encryption,
          authentication technologies and background screenings for all
          employees who have access to your student data. However, the internet
          is not perfectly secure, and Life Lessons is not responsible for
          security incidents not reasonably foreseeable or reasonably within its
          control. Customer specifically shall not provide to Life Lessons, or
          store on the System, the Social Security number, driver's license or
          state-issued identification card number, financial account number, or
          credit or debit card number of any Customer student or employee.
        </Typography>
        <h4>Representation and Warranties; Disclaimers</h4>
        <Typography paragraph sx={{ margin: '30px 0 0 0', textAlign: 'left' }}>
          Life Lessons represents and warrants that (a) it has the necessary
          authority to enter into the obligations enumerated in these TOU; (b)
          it will provide the System and related services in a professional and
          workmanlike manner and in accordance with the specifications set forth
          in the Price Quote; and (c) it will comply with all applicable laws.
          Customer represents and warrants that (a) it has the necessary
          authority to enter into the obligations enumerated in these TOU; (b)
          it has all rights, permissions and consents necessary to submit all
          Customer Content to the System and to grant Life Lessons the rights to
          use Customer Content as set forth in these TOU; (c) any material
          uploaded to the System does not contain anything that is defamatory,
          libelous, infringes upon any third party intellectual property rights,
          or violates any confidentiality obligations Customer has with a third
          party; and (d) it will comply with all applicable laws. Customer
          acknowledges that, as an internet-delivered software application, the
          System may experience periods of downtime, including (but not limited
          to) due to scheduled maintenance and third-party service outages.
          Accordingly, LIFE LESSONS DOES NOT WARRANT THAT THE SYSTEM WILL BE
          ERROR-FREE OR OPERATE WITHOUT INTERRUPTIONS OR DOWNTIME. LIFE LESSONS
          MAKES NO REPRESENTATIONS OR WARRANTIES, WHETHER EXPRESS OR IMPLIED,
          WITH RESPECT TO THE SYSTEM, INCLUDING ITS DOCUMENTATION, THE SYSTEM
          SOFTWARE, OR ANY DATA OR CONTENT MADE AVAILABLE THROUGH THE SYSTEM.
          LIFE LESSONS SPECIFICALLY DISCLAIMS ALL IMPLIED WARRANTIES OF
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT,
          AND ACCURACY.
        </Typography>
        <h4>Termination</h4>
        <Typography paragraph sx={{ margin: '30px 0 0 0', textAlign: 'left' }}>
          Life Lessons may suspend Customer's access to the Services immediately
          if Customer fails to make a payment more than 30 days following its
          due date. Customer agrees to use any professional development or
          training Services prior to termination or expiration of Customer's
          access to the System. Otherwise, Customer risks losing those Services.
          Customer shall have thirty (30) days following the termination or
          expiration of your access to the System to provide Life Lessons with a
          written request for a one-time, delimited file export of its data from
          the System. Regardless of whether Customer makes such a written
          request, and except as otherwise provided in these TOU, within ninety
          (90) days of the termination or expiration of your access, Life
          Lessons will securely destroy any and all of Customer’s personally
          identifiable student data stored in the System, including any such
          data stored in Life Lessons' backup systems.
        </Typography>
        <h4>Indemnification</h4>
        <Typography paragraph sx={{ margin: '30px 0 0 0', textAlign: 'left' }}>
          To the extent permitted by law, you shall indemnify, defend, and hold
          harmless Life Lessons and its licensors against any claim brought
          against Life Lessons and/or its licensors by a third party that arises
          from your use of the System and Services. Life Lessons agrees to: (a)
          promptly give you written notice of the claim; (b) give you sole
          control of the defense and settlement of the claim; and (c) provide
          you with reasonable assistance, at your expense, with respect to the
          defense of such claim.
        </Typography>
        <h4>LIMITATION OF LIABILITY</h4>
        <Typography paragraph sx={{ margin: '30px 0 0 0', textAlign: 'left' }}>
          IN NO EVENT WILL LIFE LESSONS OR ITS LICENSORS, EMPLOYEES, AGENTS,
          AFFILIATED AUTHORS, OR CONTRACTORS BE LIABLE FOR ANY INCIDENTAL,
          CONSEQUENTIAL, SPECIAL, INDIRECT, OR PUNITIVE DAMAGES IN CONNECTION
          WITH ANY CLAIM OF ANY NATURE, EVEN IF SUCH PARTY HAS BEEN GIVEN
          ADVANCE NOTICE OF SUCH POSSIBLE DAMAGES. IN ADDITION, LIFE LESSONS'
          AGGREGATE LIABILITY FOR ALL CLAIMS OF ANY NATURE WILL NOT EXCEED THE
          FEES ACTUALLY PAID BY YOU TO LIFE LESSONS DURING THE TWELVE (12)
          MONTHS PRECEDING THE EVENT GIVING RISE TO THE CLAIM.
        </Typography>
        <h4>Updates</h4>
        <Typography paragraph sx={{ margin: '30px 0 0 0', textAlign: 'left' }}>
          We review Terms and Conditions of Use at least annually and may change
          this agreement from time to time in the future, for example, to
          reflect changes in legal requirements or our products and
          corresponding practices, and to provide more clarity to our customers
          on our practices. Non-material changes will become effective when
          posted in Life Lessons. The “last updated” date at the top of this
          policy indicates when it was last revised.
        </Typography>
        <h4>Choice of Law and Jurisdiction</h4>
        <Typography paragraph sx={{ margin: '30px 0 0 0', textAlign: 'left' }}>
          These TOU shall be governed by and construed in accordance with the
          laws of the Commonwealth of Massachusetts, without reference to any
          conflict of law principles. You hereby submit to the exclusive
          jurisdiction of the federal and state courts located in the
          Commonwealth of Massachusetts for any disputes or claims arising out
          of your use of the Services and/or System, or these TOU.
        </Typography>
        <h4>Use by Federal Government</h4>
        <Typography paragraph sx={{ margin: '30px 0 0 0', textAlign: 'left' }}>
          The System and Services constitute Commercial Off the Shelf (“COTS”)
          items as that term is defined in the U.S. Government Federal
          Acquisition Regulations (“FAR”). Government use rights are limited to
          those minimum rights required by the appropriate provisions of the
          FAR.
        </Typography>
      </Grid>
    </Stack>
  );
};
