import React, { ReactNode } from 'react';
import { CustomAppBar } from './CustomAppBar';
import { Box } from '@mui/system';
import { useStyles } from '../providers';

interface CommonLayoutProps {
  children: ReactNode;
}

export const CommonLayout = ({ children }: CommonLayoutProps) => {
  const classes = useStyles();
  return (
    <Box
      className={classes.root}
      style={{ backgroundColor: '#FFFFFF', overflowY: 'auto' }}
    >
      {/* Render the combined Appbar and Sidebar */}
      <CustomAppBar />
      <div style={{ marginLeft: '8%', height: '100vh' }}>
        {/* Main content */}
        {children}
      </div>
    </Box>
  );
};
