import decodeJwt from 'jwt-decode';
import { apiUrl, baseCookieDomain } from '../config';

interface IframeWindow extends Window {
  userData: {
    token: string;
    lessons: string;
    username: string;
  };
}

interface CredentialResponse {
  access_token: string;
}

export const AuthProvider = {
  isAuthenticated: () => {
    const permissions = localStorage.getItem('permissions');
    if (!permissions || !AuthProvider.isSessionCookieValid('Authorization')) {
      return false;
    }
    return permissions === 'user' || permissions === 'admin' ? true : false;
  },
  hasMultiplayer: () => {
    const multiplayer = localStorage.getItem('multiplayer');
    return multiplayer ? multiplayer.toLowerCase() === 'true' : false;
  },
  hasAutoScore: () => {
    const autoscore = localStorage.getItem('autoscore');
    return autoscore ? autoscore.toLowerCase() === 'true' : false;
  },
  /**
   * Login to backend and store JSON web token on success
   *
   * @param email
   * @param password
   * @returns JSON data containing access token on success
   * @throws Error on http errors or failed attempts
   */
  login: async (email: string, password: string) => {
    // Assert email or password is not empty
    if (!(email.length > 0) || !(password.length > 0)) {
      throw new Error('Email or password was not provided');
    }
    const formData = new FormData();
    // OAuth2 expects form data, not JSON data
    formData.append('username', email);
    formData.append('password', password);

    const request = new Request(apiUrl + '/api/token', {
      method: 'POST',
      body: formData,
      credentials: 'include'
    });

    const response = await fetch(request);
    const data = await response.json();

    if (response.status === 500) {
      throw new Error('Internal server error');
    }

    if (response.status > 400 && response.status < 500) {
      if (data.detail) {
        throw data.detail;
      }
      throw data;
    }

    if ('access_token' in data) {
      const decodedToken: any = decodeJwt(data['access_token']);
      localStorage.setItem('permissions', decodedToken.permissions);
      localStorage.setItem('multiplayer', data.multiplayer);
      localStorage.setItem('autoscore', data.autoscore);
    }

    return data;
  },
  /**
   * Login to backend and store JSON web token on success
   *
   * @param credentialObj
   * @returns JSON data containing access token on success
   * @throws Error on http errors or failed attempts
   */
  googleSSOLogin: async (credentialObj: CredentialResponse) => {
    if (!(credentialObj.access_token.length > 0)) {
      throw new Error('google credential was not provided');
    }
    const formData = new FormData();
    formData.append('credential', credentialObj.access_token);
    const request = new Request(apiUrl + '/api/google_login', {
      method: 'POST',
      body: formData,
      credentials: 'include'
    });

    const response = await fetch(request);
    const data = await response.json();

    if (response.status === 500) {
      throw new Error('Internal server error');
    }

    if (response.status > 400 && response.status < 500) {
      if (data.detail) {
        throw data.detail;
      }
      throw data;
    }

    if ('access_token' in data) {
      const decodedToken: any = decodeJwt(data['access_token']);
      localStorage.setItem('permissions', decodedToken.permissions);
      localStorage.setItem('multiplayer', data.multiplayer);
      localStorage.setItem('autoscore', data.autoscore);
    }

    return data;
  },
  /**
   * Sign up via backend and store JSON web token on success
   *
   * @param email
   * @param password
   * @returns JSON data containing access token on success
   * @throws Error on http errors or failed attempts
   */
  signup: async (
    first_name: string,
    last_name: string,
    email: string,
    password: string,
    passwordConfirmation: string
  ) => {
    // Assert email or password or password confirmation is not empty
    if (!(email.length > 0)) {
      throw new Error('Email was not provided');
    }
    if (!(password.length > 0)) {
      throw new Error('Password was not provided');
    }
    if (!(passwordConfirmation.length > 0)) {
      throw new Error('Password confirmation was not provided');
    }

    const formData = new FormData();
    // OAuth2 expects form data, not JSON data
    formData.append('username', email);
    formData.append('first_name', first_name);
    formData.append('last_name', last_name);
    formData.append('password', password);

    const request = new Request(apiUrl + '/api/signup', {
      method: 'POST',
      body: formData,
      credentials: 'include'
    });

    const response = await fetch(request);

    if (response.status === 500) {
      throw new Error('Internal server error');
    }

    const data = await response.json();
    if (response.status > 400 && response.status < 500) {
      if (data.detail) {
        throw data.detail;
      }
      throw data;
    }

    if ('access_token' in data) {
      const decodedToken: any = decodeJwt(data['access_token']);
      localStorage.setItem('permissions', decodedToken.permissions);
    }

    return data;
  },
  /**
   * Reset password via backend
   *
   * @param data - An object containing the password and token fields
   * @returns JSON data containing success message
   * @throws Error on http errors or failed attempts
   */
  resetPassword: async (password: string, token: string) => {
    // Assert password and token are not empty
    if (!(password.length > 0)) {
      throw new Error('Password was not provided');
    }
    if (!(token.length > 0)) {
      throw new Error('No token found');
    }

    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token
    };

    const request = new Request(apiUrl + '/api/auth/reset_password', {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({ password: password })
    });

    const response = await fetch(request);

    if (response.status === 500) {
      throw new Error('Internal server error');
    }

    const responseData = await response.json();
    if (response.status >= 400 && response.status < 500) {
      if (responseData.detail) {
        throw new Error(responseData.detail);
      }
      throw new Error('Failed to reset password');
    }

    return responseData;
  },
  /**
   * Forgot password via backend
   *
   * @param username
   * @returns JSON data containing success message on success
   * @throws Error on http errors or failed attempts
   */
  forgotPassword: async (username: string) => {
    // Assert password or password confirmation is not empty
    if (!(username.length > 0)) {
      throw new Error('Email was not provided');
    }

    const formData = new FormData();
    // OAuth2 expects form data, not JSON data
    formData.append('email', username);

    const request = new Request(apiUrl + '/api/auth/forgot_password', {
      method: 'POST',
      body: formData
    });

    const response = await fetch(request);

    if (response.status === 500) {
      throw new Error('Internal server error');
    }

    const data = await response.json();
    if (response.status > 400 && response.status < 500) {
      if (data.detail) {
        throw data.detail;
      }
      throw data;
    }

    return data;
  },
  logout: async () => {
    localStorage.removeItem('token');
    localStorage.removeItem('permissions');
    localStorage.removeItem('multiplayer');

    const request = new Request(apiUrl + '/api/auth/clear_cookie', {
      method: 'GET',
      credentials: 'same-origin'
    });

    const response = await fetch(request);
    if (response.status > 400) {
      throw new Error('Error clearing session cookie');
    } else {
      const pastDate = new Date(0).toUTCString();
      document.cookie = `Authorization=; expires=${pastDate}; path=/`;
      window.location.href = '/';
    }
  },
  isSessionCookieValid(cookieName: string): boolean {
    const cookies = document.cookie;
    const cookiePairs = cookies.split(';');

    for (const cookiePair of cookiePairs) {
      const [name, value] = cookiePair.trim().split('=');
      if (name === cookieName) {
        const decodedValue = decodeURIComponent(value);
        try {
          const decodedToken: any = decodeJwt(decodedValue);
          if (decodedToken.exp) {
            const expirationTimeUtc = new Date(decodedToken.exp * 1000);
            const now = new Date();
            return now <= expirationTimeUtc;
          }
        } catch (error) {
          return false;
        }

        break;
      }
    }
    return false;
  }
};
