import React, { useEffect, useState } from 'react';
import { Box, Stack, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { theme, AuthProvider, LessonProvider } from '../providers';
import { Line } from 'react-chartjs-2';
import { useNavigate, useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip as ChartJSTooltip,
  Legend,
  ChartData,
  ChartEvent,
  ChartOptions,
  TooltipItem
} from 'chart.js';

interface PlayerDataPoint {
  id: number;
  value: number;
  name: string;
  description: string;
  casel_type: string;
  timestamp: string;
}

interface PlayerDataArray {
  'Self-Management': Array<PlayerDataPoint>;
  'Social Awareness': Array<PlayerDataPoint>;
  'Self-Awareness': Array<PlayerDataPoint>;
  'Responsible Decision-Making': Array<PlayerDataPoint>;
  'Relationship Skills': Array<PlayerDataPoint>;
}

interface PlayerData {
  username: string;
  data: Array<PlayerDataArray>;
}

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ChartJSTooltip,
  Legend
);

const useStyles = makeStyles(() => ({
  mainContent: {
    width: '80%',
    padding: '16px',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'auto',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '85%',
      margin: '0 auto',
      minWidth: '1000px'
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '85%',
      margin: '0 auto'
    }
  }
}));

export const PlayerHistory: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [username, setUsername] = useState<string | null>('');
  const [chartData, setChartData] = useState<ChartData<'line'> | null>(null);
  const [chartOptions, setChartOptions] = useState<ChartOptions<'line'> | null>(
    null
  );

  useEffect(() => {
    if (id) {
      fetchData(parseInt(id));
    }
  }, [id]);

  const fetchData = async (id: number) => {
    try {
      const result = await LessonProvider.getPlayerHistory(id);
      setUsername(result.username);
      setChartData(prepareChartData(result.data));
      setChartOptions(prepareChartOptions(result.data));
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const prepareChartData = (data: PlayerDataArray): ChartData<'line'> => {
    const colors = {
      'Self-Management': '#8884d8',
      'Social Awareness': '#2980b9',
      'Self-Awareness': '#82ca9d',
      'Responsible Decision-Making': '#FFBB28',
      'Relationship Skills': '#FF8042'
    };

    return {
      datasets: Object.keys(data).map((key) => {
        const sortedData = data[key as keyof PlayerDataArray].sort((a, b) => {
          const dateA = new Date(a.timestamp).getTime();
          const dateB = new Date(b.timestamp).getTime();
          return dateA - dateB;
        });
        return {
          label: key,
          data: sortedData.map((dataPoint, index) => ({
            x: index + 1,
            y: dataPoint.value,
            id: dataPoint.id,
            name: dataPoint.name,
            description: dataPoint.description
          })),
          borderColor: colors[key as keyof PlayerDataArray],
          backgroundColor: colors[key as keyof PlayerDataArray],
          fill: false
        };
      })
    };
  };

  const prepareChartOptions = (
    data: PlayerDataArray
  ): ChartOptions<'line'> => ({
    responsive: true,
    plugins: {
      tooltip: {
        callbacks: {
          title: function (context) {
            return `CASEL Competency: ${context[0]?.dataset.label}`;
          },
          label: function (context: TooltipItem<'line'>) {
            const yValue = context.parsed.y;
            const label = `  Lesson Name: ${
              (context.raw as PlayerDataPoint).name || 'N/A'
            }`;
            const description =
              (context.raw as PlayerDataPoint).description || 'No description';
            return [
              label,
              `  Lesson Score: ${yValue}`,
              `  Lesson Description: ${description}`
            ];
          }
        }
      },
      legend: {
        position: 'bottom'
      }
    },
    onClick: (event: ChartEvent, elements: Array<any>, chart: any) => {
      if (elements.length > 0) {
        const firstElement = elements[0];
        const datasetIndex = firstElement.datasetIndex;
        const dataIndex = firstElement.index;
        const dataPoint = chart.data.datasets[datasetIndex].data[
          dataIndex
        ] as PlayerDataPoint;

        navigate(
          `/lessonSummary/${dataPoint.id}?multiplayer=${encodeURIComponent(
            'true'
          )}`
        );
      }
    },
    scales: {
      x: {
        type: 'linear',
        position: 'bottom',
        title: {
          display: true,
          text: 'Lesson History By Competency'
        },
        ticks: {
          stepSize: 1
        }
      },
      y: {
        min: 1,
        max: 10,
        ticks: {
          stepSize: 1
        },
        title: {
          display: true,
          text: 'Score'
        }
      }
    }
  });

  const userAuthenticated = AuthProvider.isAuthenticated();
  const hasAutoScore = AuthProvider.hasAutoScore();
  useEffect(() => {
    if (!userAuthenticated) {
      navigate('/');
    } else if (!hasAutoScore) {
      navigate('/home');
    }
  }, [navigate, userAuthenticated, hasAutoScore]);

  return (
    <Box className={classes.mainContent}>
      <Stack spacing={6} sx={{ width: '100%', justifyContent: 'center' }}>
        <div
          onClick={() => navigate(-1)}
          style={{
            textAlign: 'left',
            textDecoration: 'none',
            cursor: 'pointer',
            display: 'block',
            margin: 0
          }}
        >
          <ArrowBackIcon sx={{ fontSize: 'medium', paddingTop: '5px' }} />
          <Typography sx={{ display: 'inline-block' }}>
            Back to Previous Page
          </Typography>
        </div>
        <Grid>
          <h3
            style={{
              color: '#3a5169',
              fontSize: 40,
              margin: '0 0 20px 10px',
              textAlign: 'left'
            }}
          >
            {username}
          </h3>
          <h5
            style={{
              color: '#3a5169',
              fontSize: 24,
              textDecoration: 'underline',
              margin: '0',
              textAlign: 'center'
            }}
          >
            CASEL Competency Growth
          </h5>
        </Grid>
        <Grid style={{ margin: '0' }}>
          {chartData && chartOptions && (
            <Line options={chartOptions} data={chartData} />
          )}
        </Grid>
      </Stack>
    </Box>
  );
};
