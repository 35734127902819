import React from 'react';
import Typography from '@mui/material/Typography';
import { useStyles } from '../providers';
import {
  Grid,
  Stack,
  List,
  ListItem,
  ListItemText,
  Divider
} from '@mui/material';
import { Link } from 'react-router-dom';

export const HowToPlayPage = () => {
  const classes = useStyles();
  return (
    <Stack spacing={4} className={classes.mainContent}>
      <Grid>
        <h5
          style={{
            color: '#3a5169',
            fontSize: 38,
            textDecoration: 'underline',
            margin: 0
          }}
        >
          How To Play
        </h5>
      </Grid>
      <Grid>
        <h4
          style={{
            color: '#2c3e50',
            fontSize: 30,
            textDecoration: 'underline',
            margin: '30px 0 0 10px',
            textAlign: 'left'
          }}
        >
          Directions
        </h4>
      </Grid>
      <Grid>
        <h5
          style={{
            color: '#2c3e50',
            fontSize: 20,
            textDecoration: 'underline',
            margin: '0 0 0 20px',
            textAlign: 'left'
          }}
        >
          Teacher-Led Mode
        </h5>
      </Grid>
      <Grid>
        <List sx={{ fontSize: '16px', padding: '0 0 20px 20px' }}>
          <ListItem sx={{ padding: '0 0 0 10px', margin: '1px' }}>
            <ListItemText primary='1. Click Home and then the Play Outpost button.' />
          </ListItem>
          <ListItem sx={{ padding: '0 0 0 10px', margin: '1px' }}>
            <ListItemText primary='2. Once Outpost loads, click Play Teacher Mode.' />
          </ListItem>
          <ListItem sx={{ padding: '0 0 0 10px', margin: '1px' }}>
            <ListItemText primary="3. Select your lesson based on the SEL competency you'd like to practice." />
          </ListItem>
          <ListItem sx={{ padding: '0 0 0 10px', margin: '1px' }}>
            <ListItemText primary='4. Configure your grade band (K-2nd or 3rd-6th), whether you want minigames enabled, then minigame, discussion and writing timers based on your ideal lesson time.' />
          </ListItem>
          <ListItem sx={{ padding: '0 0 0 10px', margin: '1px' }}>
            <ListItemText primary='5. Click Start Lesson to begin the lesson.' />
          </ListItem>
          <ListItem sx={{ padding: '0 0 0 10px', margin: '1px' }}>
            <ListItemText primary='6. Enjoy playing the lesson with your students! Follow the lesson with a discussion or activity using our discussion guide or Activity Bank.' />
          </ListItem>
        </List>
      </Grid>
      <Grid>
        <h5
          style={{
            color: '#2c3e50',
            fontSize: 20,
            textDecoration: 'underline',
            margin: '0 0 0 20px',
            textAlign: 'left'
          }}
        >
          Multiplayer Mode
        </h5>
      </Grid>
      <Grid
        style={{
          margin: '0 0 0 20px',
          textAlign: 'left'
        }}
      >
        <p>
          Below is a walkthrough on setting up multiplayer matches in Outpost:
        </p>
        <iframe
          src='https://demo.arcade.software/McZMrOaedat6vZCS6LEk?embed&show_copy_link=true'
          title='Life Lessons Multiplayer Setup'
          frameBorder='0'
          loading='lazy'
          allowFullScreen
          allow='clipboard-write'
          style={{
            width: '1200px',
            height: '700px',
            colorScheme: 'light'
          }}
        ></iframe>
      </Grid>
      <Grid>
        <h4
          style={{
            color: '#2c3e50',
            fontSize: 30,
            textDecoration: 'underline',
            margin: '30px 0 0 10px',
            textAlign: 'left'
          }}
        >
          Helpful Tips
        </h4>
      </Grid>
      <Grid>
        <Typography paragraph sx={{ margin: '30px', textAlign: 'left' }}>
          The 5 CASEL competencies are: Self-Awareness, Self-Management, Social
          Awareness, Relationship Skills, and Responsible Decision-Making. You
          can practice many of them with each lesson!
        </Typography>
        <Typography paragraph sx={{ margin: '30px', textAlign: 'left' }}>
          For early childhood educators, please use the this{' '}
          <Link to='/program'>Early Childhood Program Guide</Link> to
          incorporate our integrated early stage curriculum into your classroom.
        </Typography>
        <Typography paragraph sx={{ margin: '30px', textAlign: 'left' }}>
          Remember: The timer is a fun feature that allows you to create urgency
          with your students. It is also possible to pause or forward with the
          Pause/Next Question buttons if necessary. Following the lesson, use
          this{' '}
          <Link to='https://docs.google.com/document/d/1wqAAIgLHan64eDG0zdjxqiANimA__eC9/edit?usp=sharing&ouid=100056729591206620781&rtpof=true&sd=true'>
            discussion guide
          </Link>{' '}
          to create rich, engaging classroom discussions around the CASEL
          competency that you are practicing with your students.
        </Typography>
        <Typography paragraph sx={{ margin: '30px', textAlign: 'left' }}>
          If you run into issues loading any lesson in Outpost, try refreshing
          the page. If that doesn't work try logging out and logging back in. If
          any issues persist please e-mail luke@lifelessonsed.com.
        </Typography>
      </Grid>
    </Stack>
  );
};
