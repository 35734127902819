import React, { useState } from 'react';
import { Box } from '@mui/system';
import {
  Button,
  Grid,
  Stack,
  TextField,
  IconButton,
  InputAdornment
} from '@mui/material';
import { useParams } from 'react-router';
import { AuthProvider } from '../providers/AuthProvider';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { CustomError } from '../errorType';

export const ResetPassword = () => {
  const [password, setPassword] = useState<string>('');
  const [passwordType, setPasswordType] = useState<string>('password');
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>('');
  const [passwordError, setPasswordError] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] =
    useState<CustomError | null>(null);
  const { resetToken } = useParams();

  const redirect = () => {
    window.location.replace('https://app.lifelessonsed.com');
  };

  const togglePassword = () => {
    if (passwordType == 'text') {
      setPasswordType('password');
    } else {
      setPasswordType('text');
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (password !== passwordConfirmation) {
      setPasswordError(true);
      setPasswordErrorMessage({ code: 400, detail: 'Passwords do not match' });
    } else {
      try {
        const data = await AuthProvider.resetPassword(password, resetToken!);

        if (data) {
          redirect();
        }
      } catch (err) {
        if (err && typeof err === 'object' && 'detail' in err) {
          const customError = err as CustomError;
          setPasswordError(true);
          setPasswordErrorMessage(customError);
        } else {
          setPasswordError(true);
          setPasswordErrorMessage({
            code: 500,
            detail: 'Email not found. Please use a valid email.'
          });
        }
      }
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        textAlign: 'center'
      }}
    >
      <Box
        sx={{
          backgroundColor: 'white',
          borderRadius: 3,
          minWidth: 375,
          width: '30%',
          height: 'auto',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <form onSubmit={handleSubmit}>
          <Stack
            spacing={4}
            sx={{
              display: 'flex',
              width: '100%',
              margin: 'auto',
              paddingTop: 5,
              paddingBottom: 5,
              justifyContent: 'center'
            }}
          >
            <div>
              <h3
                style={{
                  color: '#1565c0',
                  margin: '10px',
                  fontSize: 32,
                  textAlign: 'center'
                }}
              >
                Reset Password
              </h3>
            </div>

            <Grid>
              <TextField
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setPassword(e.currentTarget.value);
                }}
                id='password'
                label='Password'
                value={password}
                type={passwordType}
                required={true}
                style={{ minWidth: '10%' }}
                fullWidth={true}
                error={passwordError}
                helperText={passwordErrorMessage?.detail}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton onClick={togglePassword}>
                        {passwordType === 'password' ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              ></TextField>
            </Grid>

            <Grid>
              <TextField
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setPasswordConfirmation(e.currentTarget.value);
                }}
                id='passwordConfirmation'
                label='Confirm Password'
                value={passwordConfirmation}
                type={passwordType}
                required={true}
                style={{ minWidth: '10%' }}
                fullWidth={true}
                error={passwordError}
                helperText={passwordErrorMessage?.detail}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton onClick={togglePassword}>
                        {passwordType === 'password' ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              ></TextField>
            </Grid>

            <Grid
              container
              spacing={1}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Grid item xs={12}>
                <Button
                  type='submit'
                  variant='contained'
                  onClick={(e: React.FormEvent) => handleSubmit(e)}
                >
                  Reset Password
                </Button>
              </Grid>
              <Grid>
                <div style={{ color: '#1d1d1d', fontSize: 16 }}>
                  <p>
                    <Link to='/'>Return to Log In</Link>
                  </p>
                </div>
              </Grid>
            </Grid>
          </Stack>
        </form>
      </Box>
    </div>
  );
};
