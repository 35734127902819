import React, { useEffect } from 'react';
import { AuthProvider, theme } from '../providers';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { useParams, Link, useNavigate } from 'react-router-dom';
import {
  Box,
  Grid,
  Stack,
  Divider,
  ListItem,
  ListItemText,
  List,
  Card,
  CardContent
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import AgricultureIcon from '@mui/icons-material/Agriculture';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import AddReactionIcon from '@mui/icons-material/AddReaction';
import DrawIcon from '@mui/icons-material/Draw';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import LightbulbCircleIcon from '@mui/icons-material/LightbulbCircle';
import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt';
import VideogameAssetIcon from '@mui/icons-material/VideogameAsset';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import ChatIcon from '@mui/icons-material/Chat';
import Face2Icon from '@mui/icons-material/Face2';
import AirIcon from '@mui/icons-material/Air';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { Units, Activities } from '../config';

type Step = {
  title: string;
  bullets: string[];
};

type Activity = {
  id: string;
  unitId: string;
  title: string;
  background: string;
  icon: string;
  time: string;
  objective: string;
  materials: Array<string>;
  steps: Step[];
};

type Activities = {
  [unitId: string]: Activity[];
};

const useStyles = makeStyles(() => ({
  mainContent: {
    width: '80%',
    padding: '16px',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '85%',
      margin: '00px 0 0 150px'
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '85%',
      margin: '0 0 0 10%'
    }
  },
  card: {
    [theme.breakpoints.up('sm')]: {
      padding: '0',
      minWidth: '350px',
      margin: '20px auto',
      minHeight: '250px',
      textAlign: 'center',
      '&:hover': {
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.5)'
      }
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0',
      minWidth: '150px',
      margin: '20px 20px 0 20px',
      textAlign: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      flexGrow: 1,
      display: 'flex',
      '&:hover': {
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.5)'
      }
    }
  },
  competencyCards: {
    display: 'flex',
    margin: '0 0 0 40px'
  },
  competencyGrid: {
    [theme.breakpoints.up('md')]: {
      marginTop: '24px'
    }
  }
}));

export const UnitPage: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { unitId } = useParams<{ unitId?: string }>();
  const selectedUnit = Units[unitId as keyof typeof Units];
  const isAuthenticated = AuthProvider.isAuthenticated();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/');
    }
  }, [isAuthenticated, navigate]);

  return (
    <Box className={classes.mainContent}>
      <Stack spacing={4} sx={{ width: '100%', justifyContent: 'center' }}>
        <Link
          to={`/program`}
          style={{
            textDecoration: 'none',
            cursor: 'pointer',
            display: 'block',
            margin: 0
          }}
        >
          <ArrowBackIcon sx={{ fontSize: 'medium', paddingTop: '5px' }} />
          <Typography sx={{ display: 'inline-block' }}>
            Back to Program Guide
          </Typography>
        </Link>
        <Grid>
          <h4
            style={{
              color: '#3a5169',
              fontSize: 34,
              textDecoration: 'underline',
              margin: 0,
              textAlign: 'left'
            }}
          >
            Unit {unitId}
          </h4>
        </Grid>
        <Grid>
          <h5
            style={{
              color: '#3a5169',
              fontSize: 38,
              margin: 0,
              textAlign: 'left'
            }}
          >
            {selectedUnit.name}
          </h5>
        </Grid>
        <Grid>
          <h5
            style={{
              color: '#3a5169',
              fontSize: 20,
              textDecoration: 'underline',
              margin: 0,
              padding: '0 0 10px 0',
              textAlign: 'left'
            }}
          >
            Description
          </h5>
          <Typography paragraph sx={{ textAlign: 'left' }}>
            {selectedUnit.description}
          </Typography>
          <h5
            style={{
              color: '#3a5169',
              fontSize: 20,
              textDecoration: 'underline',
              margin: 0,
              padding: '0 0 10px 0',
              textAlign: 'left'
            }}
          >
            Objectives
          </h5>
          <Typography paragraph sx={{ textAlign: 'left' }}>
            In this unit, students will:
          </Typography>
          <List
            sx={{
              fontSize: '20px',
              padding: '0 0 0 30px',
              margin: '1px',
              listStyleType: 'circle'
            }}
          >
            {selectedUnit.objectives.map((objective, index) => (
              <ListItem
                key={index}
                sx={{
                  fontSize: '20px',
                  padding: '0 0 0 10px',
                  margin: '1px',
                  display: 'list-item'
                }}
              >
                <ListItemText primary={objective} />
              </ListItem>
            ))}
          </List>
        </Grid>
        <Divider />
        <Grid style={{ marginTop: '12px' }}>
          <h5
            style={{
              color: '#3a5169',
              fontSize: 20,
              textDecoration: 'underline',
              margin: 0,
              padding: '0 0 10px 0',
              textAlign: 'left'
            }}
          >
            Activities
          </h5>
          <Typography paragraph sx={{ textAlign: 'left' }}>
            Below are a set of evidence-based activities in this Unit for you to
            try in your classroom to achieve this unit's objectives:
          </Typography>
        </Grid>
        <Grid
          container
          spacing={3}
          className={classes.competencyCards}
          style={{ marginTop: 0 }}
        >
          {unitId &&
            Activities[unitId as keyof typeof Activities]?.map(
              (activity: Activity) => (
                <ActivitySummary
                  key={activity.id}
                  unitId={activity.unitId}
                  id={activity.id}
                  title={activity.title}
                  icon={activity.icon}
                />
              )
            )}
        </Grid>
      </Stack>
    </Box>
  );
};

// Component for each Activity
const ActivitySummary: React.FC<{
  unitId: string;
  id: string;
  title: string;
  icon: string;
}> = ({ id, title, icon, unitId }) => {
  const classes = useStyles();
  const iconComponents = {
    emoji: EmojiEmotionsIcon,
    agri: AgricultureIcon,
    book: AutoStoriesIcon,
    toy: SmartToyIcon,
    add: AddReactionIcon,
    draw: DrawIcon,
    story: HistoryEduIcon,
    cut: ContentCutIcon,
    bulb: LightbulbCircleIcon,
    brain: PsychologyAltIcon,
    game: VideogameAssetIcon,
    page: MenuBookIcon,
    chat: ChatIcon,
    face: Face2Icon,
    air: AirIcon,
    toolbox: HomeRepairServiceIcon,
    reverse: AutorenewIcon,
    board: DashboardIcon
  };

  const DynamicIcon = iconComponents[icon as keyof typeof iconComponents];
  const count = Activities[unitId as keyof typeof Activities].length;
  const spacing = 12 / count;
  const finalSpacing = spacing <= 4 ? 4 : 6;

  return (
    <Grid item xs={12} sm={finalSpacing} sx={{ padding: 0 }}>
      <Link
        to={`/unit/${unitId}/activity/${id}`}
        style={{
          textDecoration: 'none',
          cursor: 'pointer',
          display: 'block',
          transition: 'background-color .3s ease'
        }}
      >
        <Card
          elevation={3}
          className={classes.card}
          style={{ backgroundColor: '#fcfeff' }}
        >
          <CardContent sx={{ padding: '0 !important', width: '100%' }}>
            <DynamicIcon
              style={{ fontSize: 200, color: '#3a5169', height: '250px' }}
            />
            <Typography
              variant='h6'
              align='center'
              gutterBottom
              sx={{
                backgroundColor: '#3a5169',
                padding: '12px 12px 12px 15px',
                margin: 0,
                color: 'white',
                fontSize: '20px',
                fontWeight: 'bold',
                textAlign: 'left'
              }}
            >
              {' '}
              {title}
            </Typography>
          </CardContent>
        </Card>
      </Link>
    </Grid>
  );
};
