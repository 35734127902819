import React, { useEffect, useRef, useState } from 'react';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { AuthProvider, theme, LessonProvider } from '../providers';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import {
  Box,
  Grid,
  Stack,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const useStyles = makeStyles(() => ({
  summaryContent: {
    width: '90%',
    padding: '16px',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '85%',
      margin: '0 0 0 10%'
    }
  }
}));

interface PlayerAnswer {
  first_name: string;
  last_name: string;
  answer: string;
}

interface QuestionAnswers {
  question_id: number;
  question: string;
  answers: Array<PlayerAnswer>;
}

interface MatchSummary {
  match_id: number;
  lesson_summary: string;
  timestamp: string;
  casel_type: string;
  lesson_id: number;
  user_id: number;
  player_question_answers: { [key: number]: QuestionAnswers };
}

export const MatchSummaryPage: React.FC = () => {
  const [data, setData] = useState<MatchSummary>();
  const ref = useRef<HTMLDivElement>(null);
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const isAuthenticated = AuthProvider.isAuthenticated();
  const navigate = useNavigate();
  useEffect(() => {
    if (id) {
      fetchData(id);
    }
  }, [id]);

  const fetchData = async (id: string) => {
    try {
      const data = await LessonProvider.getMatchSummary(id);

      setData(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/');
    }
  }, [isAuthenticated, navigate]);

  return (
    <Box ref={ref} className={classes.summaryContent}>
      <Stack
        spacing={4}
        sx={{
          width: '75vw',
          justifyContent: 'center',
          overflow: 'visible',
          height: 'auto'
        }}
      >
        <div
          onClick={() => navigate(-1)}
          className='print-hidden'
          style={{
            textDecoration: 'none',
            cursor: 'pointer',
            display: 'block',
            margin: 0
          }}
        >
          <ArrowBackIcon sx={{ fontSize: 'medium', paddingTop: '5px' }} />
          <Typography sx={{ display: 'inline-block' }}>
            Back to Previous Page
          </Typography>
        </div>
        <Grid item xs={12} sm={9} style={{ padding: 0 }}>
          <h4
            style={{
              color: '#3a5169',
              fontSize: 34,
              margin: 0,
              textAlign: 'left'
            }}
          >
            Match Summary
          </h4>
        </Grid>
        <Grid style={{ display: 'inherit', marginTop: '16px' }}>
          <h5
            style={{
              color: '#3a5169',
              fontSize: 20,
              textDecoration: 'underline',
              margin: 0,
              padding: '0 0 10px 0',
              textAlign: 'left'
            }}
          >
            CASEL Competency:
          </h5>
          <Typography
            paragraph
            sx={{ textAlign: 'left', margin: '1px 10px', fontSize: '18px' }}
          >
            {data && data.casel_type}
          </Typography>
        </Grid>
        <Grid style={{ display: 'inherit', marginTop: '16px' }}>
          <h5
            style={{
              color: '#3a5169',
              fontSize: 20,
              textDecoration: 'underline',
              margin: 0,
              padding: '0 0 10px 0',
              textAlign: 'left'
            }}
          >
            Lesson Description:
          </h5>
          <Typography
            paragraph
            sx={{ textAlign: 'left', margin: '1px 10px', fontSize: '18px' }}
          >
            {data && data.lesson_summary}
          </Typography>
        </Grid>
        {data &&
          Object.entries(data.player_question_answers).map(
            ([questionId, questionObj], index) => (
              <Grid container key={questionId}>
                <Grid item xs={12}>
                  <h5
                    style={{
                      color: '#3a5169',
                      fontSize: 20,
                      margin: 0,
                      padding: '20px 0 10px 0',
                      textAlign: 'left'
                    }}
                  >
                    {index + 1}. {questionObj.question}
                  </h5>
                </Grid>
                {questionObj.answers.map((answerObj, answerIndex) => (
                  <Grid item xs={12} key={answerIndex}>
                    <TextField
                      label={`${answerObj.first_name} ${answerObj.last_name}`}
                      multiline
                      rows={3}
                      value={answerObj.answer}
                      variant='outlined'
                      InputProps={{
                        readOnly: true
                      }}
                      InputLabelProps={{
                        style: { color: '#3a5169' }
                      }}
                      style={{
                        fontSize: 20,
                        margin: '10px 0 0 20px',
                        textAlign: 'left',
                        minWidth: '70%'
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            )
          )}
      </Stack>
    </Box>
  );
};
