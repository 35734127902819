import React, { useEffect, useState } from 'react';
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Button,
  Box,
  Stack,
  Grid,
  Typography
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router';
import { Link, useParams, useLocation } from 'react-router-dom';
import { AuthProvider, LessonProvider, theme } from '../providers';
import { makeStyles } from '@mui/styles';

interface Player {
  id: number;
  game_id: string;
  lesson_instance_id: string;
  user_id: number;
  email: string;
  first_name: string;
  last_name: string;
  score: number;
}

const useStyles = makeStyles({
  mainContent: {
    width: '80%',
    padding: '16px',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'auto',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '85%',
      margin: '0 0 0 8%',
      minWidth: '1000px'
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '85%',
      margin: '0 0 0 8%'
    }
  },
  emptyBody: {
    textAlign: 'center',
    padding: '20px',
    fontStyle: 'italic'
  }
});

export const PlayerResults: React.FC = () => {
  const [data, setData] = useState<Player[]>([]);
  const navigate = useNavigate();
  const classes = useStyles();
  const { gameId } = useParams<{ gameId: string }>();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const lessonName = searchParams.get('lessonName');
  const timestamp = searchParams.get('timestamp');
  const caselType = searchParams.get('caselType');
  const hasAutoScore = AuthProvider.hasAutoScore();
  const formattedDate =
    timestamp !== null
      ? new Date(timestamp).toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        })
      : 'No time found';

  useEffect(() => {
    fetchData();
  }, []);

  const handleViewMatchSummary = () => {
    navigate(`/match/${gameId}/summary`);
  };

  const fetchData = async () => {
    try {
      const gameIdString: string = gameId as string;
      const gameData = await LessonProvider.getGameSummary(gameIdString);
      setData(gameData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const hasMultiplayer = AuthProvider.hasMultiplayer();
  const userAuthenticated = AuthProvider.isAuthenticated();
  useEffect(() => {
    if (!userAuthenticated) {
      navigate('/');
    } else if (!hasMultiplayer) {
      navigate('/home');
    }
  }, [navigate, userAuthenticated, hasMultiplayer]);

  return (
    <Box className={classes.mainContent}>
      <Stack spacing={4} sx={{ width: '100%', justifyContent: 'center' }}>
        <div
          onClick={() => navigate(-1)}
          style={{
            textAlign: 'left',
            textDecoration: 'none',
            cursor: 'pointer',
            display: 'block',
            margin: 0
          }}
        >
          <ArrowBackIcon sx={{ fontSize: 'medium', paddingTop: '5px' }} />
          <Typography sx={{ display: 'inline-block' }}>
            Back to Previous Page
          </Typography>
        </div>
        <Grid container direction='row' spacing={2}>
          <Grid item xs={12} sm={9}>
            <h4
              style={{
                color: '#3a5169',
                fontSize: 34,
                textDecoration: 'underline',
                margin: 0,
                textAlign: 'left'
              }}
            >
              Player Results
            </h4>
          </Grid>
          <Grid item xs={12} sm={3} style={{ padding: '5px 0 0 0' }}>
            <Button
              variant='contained'
              color='primary'
              onClick={handleViewMatchSummary}
              style={{
                backgroundColor: '#3a5169',
                fontSize: 16,
                margin: '40px 10px 0 10px',
                minWidth: '100px'
              }}
            >
              View Match Summary
            </Button>
          </Grid>
        </Grid>
        <Grid>
          <TableContainer component={Paper}>
            <Table>
              <TableHead sx={{ backgroundColor: '#3a5169', color: '#FFF' }}>
                <TableRow>
                  <TableCell
                    sx={{
                      fontSize: '18px',
                      fontWeight: '600',
                      color: '#FFF',
                      paddingLeft: '55px'
                    }}
                  >
                    Game Count
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: '18px',
                      fontWeight: '600',
                      color: '#FFF',
                      textAlign: 'center'
                    }}
                  >
                    First Name
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: '18px',
                      fontWeight: '600',
                      color: '#FFF',
                      textAlign: 'center'
                    }}
                  >
                    Last Name
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: '18px',
                      fontWeight: '600',
                      color: '#FFF',
                      textAlign: 'center'
                    }}
                  >
                    Email
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: '18px',
                      fontWeight: '600',
                      color: '#FFF',
                      textAlign: 'center'
                    }}
                  >
                    Lesson Name
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: '18px',
                      fontWeight: '600',
                      color: '#FFF',
                      textAlign: 'center'
                    }}
                  >
                    Date Played
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: '18px',
                      fontWeight: '600',
                      color: '#FFF',
                      textAlign: 'center'
                    }}
                  >
                    CASEL Competency
                  </TableCell>
                  <TableCell
                    sx={{
                      fontSize: '18px',
                      fontWeight: '600',
                      color: '#FFF',
                      textAlign: 'center'
                    }}
                  >
                    Player Summary
                  </TableCell>
                  {hasAutoScore && (
                    <>
                      <TableCell
                        sx={{
                          fontSize: '18px',
                          fontWeight: '600',
                          color: '#FFF',
                          textAlign: 'center'
                        }}
                      >
                        Lesson Score
                      </TableCell>
                      <TableCell
                        sx={{
                          fontSize: '18px',
                          fontWeight: '600',
                          color: '#FFF',
                          textAlign: 'center'
                        }}
                      >
                        Player History
                      </TableCell>
                    </>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {data && data.length > 0 ? (
                  data.map((item, index) => (
                    <TableRow key={item.id} sx={{ fontSize: '16px' }}>
                      <TableCell sx={{ textAlign: 'center' }}>
                        {index + 1}
                      </TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>
                        {item.first_name}
                      </TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>
                        {item.last_name}
                      </TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>
                        {item.email}
                      </TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>
                        {lessonName}
                      </TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>
                        {formattedDate}
                      </TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>
                        {caselType}
                      </TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>
                        <Link
                          to={`/lessonSummary/${
                            item.lesson_instance_id
                          }?multiplayer=${encodeURIComponent('true')}`}
                        >
                          <Button sx={{ color: '#3a5169' }}>
                            {' '}
                            Lesson Summary
                          </Button>
                        </Link>
                      </TableCell>
                      {hasAutoScore && (
                        <>
                          <TableCell sx={{ textAlign: 'center' }}>
                            {item.score ? item.score : 'NA'} / 10
                          </TableCell>
                          <TableCell sx={{ textAlign: 'center' }}>
                            <Link to={`/playerHistory/${item.user_id}`}>
                              <Button sx={{ color: '#3a5169' }}>
                                {' '}
                                Player History
                              </Button>
                            </Link>
                          </TableCell>
                        </>
                      )}
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={3} className={classes.emptyBody}>
                      <Typography variant='body1'>
                        Game history loading or no data available yet. Please
                        try a game first!
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Stack>
    </Box>
  );
};
