import { createTheme, ThemeProvider } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

const theme = createTheme({
  zIndex: {
    appBar: 0,
    drawer: 1
  },
  palette: {
    primary: {
      main: '#1976D2'
    },
    background: {
      default: '#F8F8F8'
    }
  }
});

const useStyles = makeStyles(() => ({
  root: {
    maxHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  mainContent: {
    width: '100%',
    padding: '8px',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '85%',
      margin: '0 0 0 120px'
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '85%',
      margin: '0 0 0 8%'
    }
  },
  responsiveText: {
    fontSize: '4vw',
    textAlign: 'right',
    [theme.breakpoints.up('sm')]: {
      fontSize: '24px'
    }
  },
  button: {
    width: '100%',
    backgroundColor: '#e74c3c !important',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    justifyContent: 'center',
    padding: '6px !important',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '600px',
      fontSize: '12px'
    },
    [theme.breakpoints.down('sm')]: {}
  },
  regButton: {
    backgroundColor: '#e74c3c !important',
    color: 'white'
  },
  greyedButton: {
    backgroundColor: 'grey !important',
    color: 'white',
    pointerEvents: 'none'
  },
  '@global': {
    '@media print': {
      html: {
        overflow: 'initial !important',
        height: 'initial !important'
      },
      body: {
        overflow: 'initial !important',
        height: 'initial !important'
      }
    }
  }
}));

export { theme, ThemeProvider, useStyles };
