import React, { useEffect, useState } from 'react';
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Button,
  Box,
  Stack,
  Grid,
  Typography
} from '@mui/material';
import { useNavigate } from 'react-router';
import { Link, useParams } from 'react-router-dom';
import { AuthProvider, LessonProvider, theme } from '../providers';
import { makeStyles } from '@mui/styles';
import { LineChart } from '@mui/x-charts/LineChart';
import { keys } from '@mui/system';

interface Game {
  id: number;
  code: string;
  lesson_name: string;
  creator: number;
  completed: boolean;
  created_at: string;
  casel_type: string;
}

const useStyles = makeStyles({
  mainContent: {
    width: '80%',
    padding: '16px',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'auto',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '85%',
      margin: '0',
      minWidth: '1000px'
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '85%',
      margin: '0 0 0 8%'
    }
  },
  emptyBody: {
    textAlign: 'center',
    padding: '20px',
    fontStyle: 'italic'
  }
});

export const ClassroomResults: React.FC = () => {
  const [data, setData] = useState({});
  const [xData, setXAxis] = useState<Array<number>>([]);
  const navigate = useNavigate();
  const classes = useStyles();
  const { date } = useParams<{ date: string }>();
  useEffect(() => {
    if (date) {
      fetchData(date);
    }
  }, []);

  const fetchData = async (date: string) => {
    try {
      const d = new Date(date);
      const dateStr =
        d.getFullYear().toString() +
        (d.getMonth() + 1).toString().padStart(2, '0') +
        d.getDate().toString().padStart(2, '0');
      const chartData = await LessonProvider.getClassroomData(dateStr);
      const xData = Array.from(Array(chartData.max).keys());
      const dataset = chartData.dataset;
      setXAxis(xData);
      setData(dataset);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const hasMultiplayer = AuthProvider.hasMultiplayer();
  const userAuthenticated = AuthProvider.isAuthenticated();

  const handlePointClick = async (list: any, pointIndex: any) => {
    const matchId = list[pointIndex][0];
    const gameKey = await LessonProvider.getGameKey(matchId as number);
    navigate(`/survey/${gameKey}`);
  };

  useEffect(() => {
    if (!userAuthenticated) {
      navigate('/');
    } else if (!hasMultiplayer) {
      navigate('/home');
    }
  }, [navigate, userAuthenticated, hasMultiplayer]);

  return (
    <Box className={classes.mainContent}>
      <Stack spacing={4} sx={{ width: '100%', justifyContent: 'center' }}>
        <Grid>
          <h4
            style={{
              color: '#3a5169',
              fontSize: 34,
              textDecoration: 'underline',
              margin: 0,
              textAlign: 'left',
              paddingBottom: 5
            }}
          >
            Aggregate Classroom Chart
          </h4>
          <p
            style={{
              color: '#3a5169',
              fontSize: 24,
              textDecoration: 'underline',
              margin: 0,
              textAlign: 'left',
              paddingBottom: 15
            }}
          >
            10 Most Recent Matches
          </p>
          {data && Object.keys(data).length > 0 ? (
            Object.keys(data).map((key, i) => (
              <Grid xs={12} sx={{ textAlign: 'left', paddingBottom: 2 }}>
                <Grid item sx={{ display: 'inline-block', textAlign: 'left' }}>
                  <Typography sx={{ fontSize: 17 }}>{i + 1}.</Typography>
                </Grid>
                <Grid item sx={{ display: 'inline-block', textAlign: 'left' }}>
                  <Typography
                    style={{
                      color: '#3a5169',
                      fontSize: 18,
                      textAlign: 'left',
                      alignItems: 'center',
                      paddingLeft: 15
                    }}
                  >
                    {key}
                  </Typography>
                </Grid>
                <LineChart
                  xAxis={[
                    {
                      min: 1,
                      data: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                      label: 'Match History',
                      scaleType: 'linear'
                    }
                  ]}
                  yAxis={[{ min: 1, max: 5, label: 'Response (1-5)' }]}
                  series={Object.values(data[key as keyof object]).map(
                    (item: any, index) => ({
                      data: item.map((tuple: any[]) => tuple[0]),
                      id: item.map((tuple: any[]) => [tuple[1], index]),
                      curve: 'linear',
                      label: Object.keys(data[key as keyof object])[index]
                    })
                  )}
                  onMarkClick={(event, d) =>
                    handlePointClick(d['seriesId'], d['dataIndex'])
                  }
                  width={745}
                  height={390}
                />
              </Grid>
            ))
          ) : (
            <p>Survey history loading or no data available yet.</p>
          )}
        </Grid>
      </Stack>
    </Box>
  );
};
