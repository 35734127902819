import React, { FC } from 'react';
import { Box } from '@mui/system';
import { Stack, Grid } from '@mui/material';
import logo from '../assets/logo.png';
import { Link } from 'react-router-dom';
export const ForgotPasswordSubmit: FC = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh'
      }}
    >
      <Box
        sx={{
          backgroundColor: 'white',
          borderRadius: 3,
          border: 'solid #E0E0E0',
          borderWidth: '1px',
          minWidth: 375,
          width: '30%',
          height: 'auto',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Stack
          spacing={4}
          sx={{
            display: 'flex',
            width: '100%',
            margin: 'auto',
            padding: '20px',
            justifyContent: 'center'
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              color: '#1565c0',
              fontSize: 15,
              margin: 0
            }}
          >
            <img
              src={logo}
              style={{
                justifyContent: 'center',
                height: '150px',
                width: '150px',
                padding: '10px'
              }}
            ></img>
            <p style={{ padding: '30px 20px 0' }}>
              Reset Email submitted. If you have an existing account under this
              email you should receive an email to reset your password in a few
              minutes. Please check your email and follow the link.
            </p>
          </div>
          <Grid>
            <div
              style={{ color: '#1d1d1d', fontSize: 16, textAlign: 'center' }}
            >
              <p>
                <Link to='/'>Return to Log In</Link>
              </p>
            </div>
          </Grid>
        </Stack>
      </Box>
    </div>
  );
};
