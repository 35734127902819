import React, { useState } from 'react';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import {
  useMediaQuery,
  Box,
  Drawer as MuiDrawer,
  AppBar as MuiAppBar,
  AppBarProps as MuiAppBarProps,
  Toolbar,
  List,
  CssBaseline,
  Typography,
  Divider,
  IconButton
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import ArticleIcon from '@mui/icons-material/Article';
import LogoutIcon from '@mui/icons-material/Logout';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import VideogameAssetIcon from '@mui/icons-material/VideogameAsset';
import GroupsIcon from '@mui/icons-material/Groups';
import WebIcon from '@mui/icons-material/Web';
import logo from '../assets/logo.png';
import { AuthProvider } from '../providers';
import { useNavigate, useLocation } from 'react-router-dom';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden'
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`
  }
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme)
  })
}));

export const CustomAppBar = () => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(!isMobile);
  const hasMultiplayer = AuthProvider.hasMultiplayer();
  const getCurrentLocation = () => location.pathname.split('/')[1];

  const handleDrawerOpen = () => setOpen(true);
  const handleDrawerClose = () => setOpen(false);

  const handleNavigation = (action: () => void) => {
    action();
  };

  const navigationItems = [
    {
      icon: HomeIcon,
      text: 'Home',
      action: () => navigate('/home'),
      path: 'home'
    },
    {
      icon: AspectRatioIcon,
      text: 'How To Play',
      action: () => navigate('/howtoplay'),
      path: 'howtoplay'
    },
    {
      icon: ArticleIcon,
      text: 'CASEL Overview',
      action: () => navigate('/casel'),
      path: 'casel'
    },
    {
      icon: AnalyticsIcon,
      text: 'Program Guide',
      action: () => navigate('/program'),
      path: 'program'
    },
    {
      icon: WebIcon,
      text: 'Lesson History',
      action: () => navigate('/lessonHistory'),
      path: 'lessonHistory'
    },
    {
      icon: VideogameAssetIcon,
      text: 'Game History',
      action: () => navigate('/gameHistory'),
      condition: hasMultiplayer,
      path: 'gameHistory'
    },
    {
      icon: GroupsIcon,
      text: 'Survey History',
      action: () => navigate('/surveyHistory'),
      condition: hasMultiplayer,
      path: 'surveyHistory'
    },
    {
      icon: LogoutIcon,
      text: 'Logout',
      action: () => AuthProvider.logout(),
      path: 'logout'
    }
  ];

  const appBarStyles = {
    backgroundColor: '#e74c3c',
    minHeight: '80px',
    justifyContent: 'center',
    height: '80px'
  };

  const drawerHeaderStyles = {
    backgroundColor: '#e74c3c',
    color: 'white',
    minHeight: '80px',
    justifyContent: 'center',
    textAlign: 'center',
    height: '80px'
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position='fixed' open={open} sx={appBarStyles}>
        <Toolbar>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            onClick={handleDrawerOpen}
            edge='start'
            sx={{ marginRight: 5, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          <img
            src={logo}
            alt='Logo'
            style={{
              maxWidth: '50px',
              marginRight: '20px',
              marginLeft: '20px'
            }}
          />
          <Typography variant='h6' noWrap component='div'>
            Life Lessons
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer variant='permanent' open={open}>
        <DrawerHeader sx={drawerHeaderStyles}>
          <Typography variant='h6' noWrap component='div'>
            Menu
          </Typography>
          <IconButton
            onClick={handleDrawerClose}
            sx={{ color: 'white', marginLeft: 'auto' }}
          >
            {theme.direction === 'rtl' ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List sx={{ backgroundColor: 'transparent' }}>
          {navigationItems.map(
            ({ icon: Icon, text, action, condition, path }) => {
              if (condition !== undefined && !condition) {
                return null;
              }
              const isSelected = getCurrentLocation() === path.toLowerCase();
              return (
                <ListItem
                  key={text}
                  disablePadding
                  onClick={() => handleNavigation(action)}
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                      backgroundColor: isSelected ? '#F0F0F0' : 'transparent'
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center'
                      }}
                    >
                      <Icon />
                    </ListItemIcon>
                    <ListItemText
                      primary={text}
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </ListItem>
              );
            }
          )}
        </List>
        <Divider />
      </Drawer>
      <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        {/* Main content here */}
      </Box>
    </Box>
  );
};
