import React, { useState } from 'react';
import { Box, styled } from '@mui/system';
import {
  Button,
  Grid,
  Paper,
  TextField,
  Stack,
  IconButton,
  InputAdornment
} from '@mui/material';
import { Link } from 'react-router-dom';
import { AuthProvider } from '../providers/AuthProvider';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useNavigate } from 'react-router';
import logo from '../assets/logo.png';

const FormContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1)
}));

const MarginContainer = styled(Grid)(({ theme }) => ({
  margin: theme.spacing(2)
}));

const ButtonText = styled(Button)(() => ({
  textTransform: 'none'
}));

export const SignUp = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>('');
  const [firstname, setFirstName] = useState<string>('');
  const [lastname, setLastName] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [passwordType, setPasswordType] = useState<string>('password');
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>('');

  // Error States //
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState('');

  const togglePassword = () => {
    if (passwordType == 'text') {
      setPasswordType('password');
    } else {
      setPasswordType('text');
    }
  };

  const validateInput = (inputValue: string, fieldName: string) => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (inputValue.trim() === '') {
      setError(true);
      setErrorMessage('This field is required.');
    } else if (fieldName == 'email' && !emailRegex.test(inputValue.trim())) {
      setEmailError(true);
      setEmailErrorMessage('Valid email required');
    } else {
      setError(false);
      setEmailError(false);
      setPasswordError(false);
      setErrorMessage('');
      setEmailErrorMessage('');
      setPasswordErrorMessage('');
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (password !== passwordConfirmation) {
      setPasswordError(true);
      setPasswordErrorMessage('Passwords do not match');
    } else {
      try {
        const data = await AuthProvider.signup(
          firstname,
          lastname,
          email,
          password,
          passwordConfirmation
        );

        if (data) {
          navigate('/');
        }
      } catch (err) {
        if (err instanceof Error) {
          // handle errors thrown from frontend
          setError(true);
          setErrorMessage(err.message);
        } else {
          // handle errors thrown from backend
          setError(true);
          setErrorMessage(String(err));
        }
      }
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        minHeight: '100vh'
      }}
    >
      <Box
        sx={{
          backgroundColor: 'white',
          borderRadius: 3,
          border: 'solid #E0E0E0',
          borderWidth: '1px',
          minWidth: 375,
          width: '30%',
          height: 'auto',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <form onSubmit={handleSubmit}>
          <Stack
            spacing={4}
            sx={{
              display: 'flex',
              width: '75%',
              margin: 'auto',
              paddingTop: 5,
              paddingBottom: 5,
              justifyContent: 'center'
            }}
          >
            <div>
              <img
                src={logo}
                style={{
                  justifyContent: 'center',
                  height: '150px',
                  width: '150px'
                }}
              ></img>
              <h5
                style={{
                  color: '#1565c0',
                  margin: 'auto',
                  fontSize: 32
                }}
              >
                Sign Up
              </h5>
            </div>

            <Grid style={{ marginTop: '20px' }}>
              <TextField
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  validateInput(e.currentTarget.value, 'firstname');
                  setFirstName(e.currentTarget.value);
                }}
                id='first_name'
                label='First Name'
                value={firstname}
                type='text'
                required={true}
                style={{ minWidth: 235 }}
                fullWidth={true}
                autoFocus={true}
                error={error}
                helperText={errorMessage}
              />
            </Grid>

            <Grid style={{ marginTop: '20px' }}>
              <TextField
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  validateInput(e.currentTarget.value, 'lastname');
                  setLastName(e.currentTarget.value);
                }}
                id='last_name'
                label='Last Name'
                value={lastname}
                type='text'
                required={true}
                style={{ minWidth: 235 }}
                fullWidth={true}
                error={error}
                helperText={errorMessage}
              />
            </Grid>

            <Grid style={{ marginTop: '20px' }}>
              <TextField
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setEmail(e.currentTarget.value);
                  validateInput(e.currentTarget.value, 'email');
                }}
                id='email'
                label='Email'
                value={email}
                type='email'
                required={true}
                style={{ minWidth: 235 }}
                fullWidth={true}
                error={emailError}
                helperText={emailErrorMessage}
              />
            </Grid>

            <Grid style={{ marginTop: '20px' }}>
              <TextField
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setPassword(e.currentTarget.value);
                }}
                id='password'
                label='Password'
                value={password}
                type={passwordType}
                required={true}
                style={{ minWidth: '10%' }}
                fullWidth={true}
                error={passwordError}
                helperText={passwordErrorMessage}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton onClick={togglePassword}>
                        {passwordType === 'password' ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              ></TextField>
            </Grid>

            <Grid style={{ marginTop: '20px' }}>
              <TextField
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setPasswordConfirmation(e.currentTarget.value);
                }}
                id='passwordConfirmation'
                label='Confirm Password'
                value={passwordConfirmation}
                type={passwordType}
                required={true}
                style={{ minWidth: '10%' }}
                fullWidth={true}
                error={passwordError}
                helperText={passwordErrorMessage}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton onClick={togglePassword}>
                        {passwordType === 'password' ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              ></TextField>
            </Grid>

            <Grid
              container
              spacing={1}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Grid item xs={12}>
                <Button
                  type='submit'
                  variant='contained'
                  onClick={(e: React.FormEvent) => handleSubmit(e)}
                  startIcon={
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                      }}
                    >
                      <AccountCircleIcon />
                    </div>
                  }
                >
                  Sign Up
                </Button>
              </Grid>
              <Grid>
                <div style={{ color: '#1d1d1d', fontSize: 16 }}>
                  <p>
                    already have an account? <Link to='/'>Log In</Link>
                  </p>
                </div>
              </Grid>
            </Grid>
          </Stack>
        </form>
      </Box>
    </div>
  );
};
