import { apiUrl, baseCookieDomain } from '../config';

export const LessonProvider = {
  /**
   * Get lesson history for user
   *
   * @returns JSON data containing lesson history
   * @throws Error on http errors or failed attempts
   */
  getLessonHistory: async () => {
    const request = new Request(apiUrl + '/api/v1/lesson_instances/me', {
      method: 'GET',
      credentials: 'include'
    });
    const response = await fetch(request);

    if (response.status === 500) {
      throw new Error('Internal server error');
    }

    const data = await response.json();
    if (response.status > 400 && response.status < 500) {
      if (data.detail) {
        throw data.detail;
      }
      throw data;
    }

    return data;
  },
  /**
   * Get game history for user
   *
   * @returns JSON data containing game history
   * @throws Error on http errors or failed attempts
   */
  getGameHistory: async () => {
    const request = new Request(apiUrl + '/api/v1/game/list', {
      method: 'GET',
      credentials: 'include'
    });
    const response = await fetch(request);

    if (response.status === 500) {
      throw new Error('Internal server error');
    }

    const data = await response.json();
    if (response.status > 400 && response.status < 500) {
      if (data.detail) {
        throw data.detail;
      }
      throw data;
    }

    return data;
  },
  /**
   * Get game code by id
   *
   * @returns string representing game code
   * @throws Error on http errors or failed attempts
   */
  getGameKey: async (id: number) => {
    const request = new Request(apiUrl + `/api/v1/game/key/${id}`, {
      method: 'GET',
      credentials: 'include'
    });
    const response = await fetch(request);

    if (response.status === 500) {
      throw new Error('Internal server error');
    }

    const data = await response.json();
    if (response.status > 400 && response.status < 500) {
      if (data.detail) {
        throw data.detail;
      }
      throw data;
    }

    return data;
  },
  /**
   * Get classroom aggregation
   *
   * @returns JSON data containing game history
   * @throws Error on http errors or failed attempts
   */
  getClassroomSurveys: async () => {
    const request = new Request(apiUrl + '/api/v1/game/classroom/surveys', {
      method: 'GET',
      credentials: 'include'
    });
    const response = await fetch(request);

    if (response.status === 500) {
      throw new Error('Internal server error');
    }

    const data = await response.json();
    if (response.status > 400 && response.status < 500) {
      if (data.detail) {
        throw data.detail;
      }
      throw data;
    }

    return data;
  },
  /**
   * Get game summary for game
   *
   * @returns JSON data containing game summary
   * @throws Error on http errors or failed attempts
   */
  getGameSummary: async (id: string) => {
    const request = new Request(apiUrl + `/api/v1/game/player_list/${id}`, {
      method: 'GET',
      credentials: 'include'
    });
    const response = await fetch(request);

    if (response.status === 500) {
      throw new Error('Internal server error');
    }

    const data = await response.json();
    if (response.status > 400 && response.status < 500) {
      if (data.detail) {
        throw data.detail;
      }
      throw data;
    }

    return data;
  },
  /**
   * Get lesson summary of lesson instance
   *
   * @returns JSON data containing lesson summary
   * @throws Error on http errors or failed attempts
   */
  getLessonSummary: async (id: number, multiplayer = 'false') => {
    const request = new Request(
      apiUrl +
        `/api/v1/lesson_instances/${id}/summary?multiplayer=${multiplayer}`,
      {
        method: 'GET',
        credentials: 'include'
      }
    );
    const response = await fetch(request);

    if (response.status === 500) {
      throw new Error('Internal server error');
    }

    const data = await response.json();
    if (response.status > 400 && response.status < 500) {
      if (data.detail) {
        throw data.detail;
      }
      throw data;
    }

    return data;
  },
  /**
   * Get summary of a match
   *
   * @returns JSON data containing match summary
   * @throws Error on http errors or failed attempts
   */
  getMatchSummary: async (id: string) => {
    const request = new Request(apiUrl + `/api/v1/game/${id}/summary`, {
      method: 'GET',
      credentials: 'include'
    });
    const response = await fetch(request);

    if (response.status === 500) {
      throw new Error('Internal server error');
    }

    const data = await response.json();
    if (response.status > 400 && response.status < 500) {
      if (data.detail) {
        throw data.detail;
      }
      throw data;
    }

    return data;
  },
  /**
   * Get summary of a survey from a match
   *
   * @returns JSON data containing match summary
   * @throws Error on http errors or failed attempts
   */
  getSurveySummary: async (id: string) => {
    const request = new Request(apiUrl + `/api/v1/game/survey/${id}/summary`, {
      method: 'GET',
      credentials: 'include'
    });
    const response = await fetch(request);

    if (response.status === 500) {
      throw new Error('Internal server error');
    }

    const data = await response.json();
    if (response.status > 400 && response.status < 500) {
      if (data.detail) {
        throw data.detail;
      }
      throw data;
    }

    return data;
  },
  /**
   * Get survey data for a classroom
   *
   * @returns JSON data containing match summary
   * @throws Error on http errors or failed attempts
   */
  getClassroomData: async (date: string) => {
    const request = new Request(
      apiUrl + `/api/v1/game/classroom/${date}/data`,
      {
        method: 'GET',
        credentials: 'include'
      }
    );
    const response = await fetch(request);

    if (response.status === 500) {
      throw new Error('Internal server error');
    }

    const data = await response.json();
    if (response.status > 400 && response.status < 500) {
      if (data.detail) {
        throw data.detail;
      }
      throw data;
    }

    return data;
  },
  /**
   * Get player history
   *
   * @returns JSON data containing player history
   * @throws Error on http errors or failed attempts
   */
  getPlayerHistory: async (id: number) => {
    const request = new Request(apiUrl + `/api/v1/game/player/${id}/history`, {
      method: 'GET',
      credentials: 'include'
    });
    const response = await fetch(request);

    if (response.status === 500) {
      throw new Error('Internal server error');
    }

    const data = await response.json();
    if (response.status > 400 && response.status < 500) {
      if (data.detail) {
        throw data.detail;
      }
      throw data;
    }

    return data;
  }
};
