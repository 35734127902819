import React, { useEffect } from 'react';
import { gameUrl } from '../config';
import Typography from '@mui/material/Typography';
import { useStyles, AuthProvider } from '../providers';
import {
  Box,
  Grid,
  Stack,
  Button,
  useMediaQuery,
  List,
  ListItem,
  ListItemText
} from '@mui/material';
import AndroidIcon from '@mui/icons-material/Android';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import ArticleIcon from '@mui/icons-material/Article';
import { Link, useNavigate } from 'react-router-dom';

export const HomePage: React.FC = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:600px)');
  useEffect(() => {
    const game = document.getElementById('godot-game');
    if (game) {
      game.style.display = 'none';
      game.style.zIndex = '-1';
    }
    // Create and preload the iframe when the login component mounts
    const iframe = document.createElement('iframe');
    iframe.id = 'godot-game';
    iframe.src = gameUrl;
    iframe.style.width = '100%';
    iframe.style.height = '100%';
    iframe.style.zIndex = '-1';
    iframe.style.borderRadius = '3';
    iframe.style.border = 'solid black';
    iframe.style.borderWidth = '1px';
    iframe.style.position = 'fixed';
    iframe.style.top = '0';
    iframe.style.left = '0';
    iframe.style.bottom = '0';
    iframe.style.right = '0';
    iframe.style.margin = '0';
    iframe.style.padding = '0';
    iframe.style.display = 'none';

    // Set sandbox attributes to allow same-origin and scripts
    iframe.sandbox.add('allow-same-origin');
    iframe.sandbox.add('allow-scripts');

    // Append the iframe to the DOM
    document.body.appendChild(iframe);
  }, []);

  const handleGameLoad = () => {
    const game = document.getElementById('godot-game');
    if (AuthProvider.isAuthenticated() && game) {
      game.style.display = 'block';
      game.style.zIndex = '2';
      game.style.width = '100%';
      game.style.height = '100%';
      game.style.position = 'fixed';
      game.style.top = '0';
      game.style.left = '0';
      game.style.bottom = '0';
      game.style.right = '0';
      game.style.margin = '0';
      game.style.padding = '0';
    }
  };

  const handleCASELLoad = () => {
    navigate('/casel');
  };

  const handleHowToPlayLoad = () => {
    navigate('/howtoplay');
  };

  const classes = useStyles();

  useEffect(() => {
    if (!AuthProvider.isAuthenticated()) {
      navigate('/');
    }
  }, [navigate]);

  return (
    <Box className={classes.mainContent}>
      <Stack spacing={4} sx={{ width: '100%', justifyContent: 'center' }}>
        <Grid>
          <h5
            style={{
              color: '#3a5169',
              fontSize: 32,
              textDecoration: 'underline',
              margin: '0'
            }}
          >
            Welcome to Life Lessons
          </h5>
        </Grid>
        <Grid>
          <Typography paragraph sx={{ margin: '20px', textAlign: 'left' }}>
            We believe that all students have the potential to learn and build
            strong social emotional skills. Building those skills is a process
            that unfolds over time, and should be supported by having the right
            opportunities to practice these skills.
          </Typography>
          <Typography paragraph sx={{ margin: '20px', textAlign: 'left' }}>
            Our program is designed to provide students with developmentally
            appropriate opportunities to exercise social emotional learning
            competencies in context. The format for our lessons follow our
            framework outlined below. Each lesson in Outpost provides students
            an opportunity to:
          </Typography>
          <List
            sx={{
              fontSize: '20px',
              padding: '0 0 0 50px',
              margin: '1px',
              listStyleType: 'disc'
            }}
          >
            <ListItem
              sx={{
                fontSize: '20px',
                padding: '0 0 0 10px',
                margin: '1px',
                display: 'list-item'
              }}
            >
              <ListItemText primary='Encounter a relatable SEL challenge.' />
            </ListItem>
            <ListItem
              sx={{
                fontSize: '20px',
                padding: '0 0 0 10px',
                margin: '1px',
                display: 'list-item'
              }}
            >
              <ListItemText primary='Identify the emotions for the characters and emotional vocabulary involved.' />
            </ListItem>
            <ListItem
              sx={{
                fontSize: '20px',
                padding: '0 0 0 10px',
                margin: '1px',
                display: 'list-item'
              }}
            >
              <ListItemText primary='Engage with personal experience regarding these emotions with classmates.' />
            </ListItem>
            <ListItem
              sx={{
                fontSize: '20px',
                padding: '0 0 0 10px',
                margin: '1px',
                display: 'list-item'
              }}
            >
              <ListItemText primary='Find empathetic strategies for managing emotions.' />
            </ListItem>
            <ListItem
              sx={{
                fontSize: '20px',
                padding: '0 0 0 10px',
                margin: '1px',
                display: 'list-item'
              }}
            >
              <ListItemText primary='Resolve the SEL challenge and come together to discuss learnings.' />
            </ListItem>
          </List>
          <Typography paragraph sx={{ margin: '20px', textAlign: 'left' }}>
            For early childhood educators, please use the{' '}
            <Link to='/program'>Early Childhood Program Guide</Link> to
            incorporate our integrated early stage curriculum into your
            classroom.
          </Typography>
          <Typography paragraph sx={{ margin: '20px', textAlign: 'left' }}>
            Please feel free to read through the instructions in How To Play
            before beginning a lesson in Outpost. This will include helpful tips
            around facilitating the lesson so you can maximize the experience
            for your students.
          </Typography>
          <Typography paragraph sx={{ margin: '20px', textAlign: 'left' }}>
            Following any lesson, use this{' '}
            <Link to='https://docs.google.com/document/d/1wqAAIgLHan64eDG0zdjxqiANimA__eC9/edit?usp=sharing&ouid=100056729591206620781&rtpof=true&sd=true'>
              discussion guide
            </Link>{' '}
            to create rich, engaging classroom discussions around the CASEL
            competency that you are practicing with your students.
          </Typography>
        </Grid>
        <Stack spacing={4} sx={{ width: '100%', justifyContent: 'center' }}>
          <Grid container spacing={4} sx={{ justifyContent: 'center' }}>
            <Grid item xs={12} sm={4}>
              <Button
                variant='contained'
                className={classes.button}
                startIcon={<AspectRatioIcon />}
                onClick={() => handleHowToPlayLoad()}
              >
                How To Play
              </Button>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Button
                variant='contained'
                className={classes.button}
                startIcon={<ArticleIcon />}
                onClick={() => handleCASELLoad()}
              >
                CASEL Overview
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant='contained'
                className={classes.regButton}
                onClick={() => handleGameLoad()}
                startIcon={<AndroidIcon />}
              >
                Play Outpost
              </Button>
              {isMobile && (
                <Typography variant='body1' sx={{ marginTop: '10px' }}>
                  Please rotate your phone to landscape mode to play Outpost on
                  mobile.
                </Typography>
              )}
            </Grid>
          </Grid>
        </Stack>
      </Stack>
    </Box>
  );
};
