import React, { useEffect, useState } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { Box } from '@mui/system';
import {
  Button,
  Grid,
  Stack,
  TextField,
  IconButton,
  InputAdornment
} from '@mui/material';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { AuthProvider } from '../providers/AuthProvider';
import LoginIcon from '@mui/icons-material/Login';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import logo from '../assets/logo.png';
import { CustomError } from '../errorType';
import clever from '../assets/clever.png';
import { GoogleLoginButton } from 'react-social-login-buttons';

export const Login = () => {
  const navigate = useNavigate();
  const [emailInput, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<CustomError | null>(null);
  const [passwordType, setPasswordType] = useState<string>('password');

  const togglePassword = () => {
    if (passwordType == 'text') {
      setPasswordType('password');
    } else {
      setPasswordType('text');
    }
  };

  // const handleCleverLogin = async () => {
  //   window.location.href = `https://clever.com/oauth/authorize?response_type=code&redirect_uri=http://localhost:8000/home&client_id=d8db58eb1964148135c9`;
  // };

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: (tokenResponse) => fetchGoogleUserData(tokenResponse)
  });

  const fetchGoogleUserData = async (credentialResponse: any) => {
    try {
      const data = await AuthProvider.googleSSOLogin(credentialResponse);
      if (data.access_token && data.access_token.length > 0) {
        console.log('hit home');
        navigate('/home');
      }
    } catch (err) {
      if (err && typeof err === 'object' && 'detail' in err) {
        const customError = err as CustomError;
        setError(true);
        setErrorMessage(customError);
      } else {
        console.log(err);
        setError(true);
        setErrorMessage({
          code: 500,
          detail: 'Unable to login via google. Please try a different method.'
        });
      }
    }
  };

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    setErrorMessage(null);
    try {
      const data = await AuthProvider.login(emailInput, password);

      if (data) {
        navigate('/home');
      }
    } catch (err) {
      if (err && typeof err === 'object' && 'detail' in err) {
        const customError = err as CustomError;
        setError(true);
        setErrorMessage(customError);
      } else {
        setError(true);
        setErrorMessage({ code: 500, detail: 'Incorrect email or password.' });
      }
    }
  };
  const userAuthenticated = AuthProvider.isAuthenticated();

  const validateInput = (inputValue: string) => {
    if (inputValue.trim() === '') {
      setError(true);
      setErrorMessage({ code: 500, detail: 'This field is required' });
    } else {
      setError(false);
      setErrorMessage(null);
    }
  };

  useEffect(() => {
    if (userAuthenticated) {
      navigate('/home');
    }
  });

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh'
      }}
    >
      <Box
        sx={{
          backgroundColor: 'white',
          borderRadius: 3,
          border: 'solid #E0E0E0',
          borderWidth: '1px',
          minWidth: 375,
          width: '30%',
          height: 'auto',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          minHeight: '50vh',
          flexDirection: 'column'
        }}
      >
        <form onSubmit={handleLogin}>
          <Stack
            spacing={4}
            sx={{
              display: 'flex',
              width: '100%',
              margin: 'auto',
              paddingTop: 5,
              paddingBottom: 5,
              justifyContent: 'center'
            }}
          >
            <div>
              <img
                src={logo}
                style={{
                  justifyContent: 'center',
                  height: '150px',
                  width: '150px'
                }}
              ></img>
              <h5
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  color: '#1565c0',
                  fontSize: 32,
                  margin: 0
                }}
              >
                Log In
              </h5>
            </div>
            <Grid style={{ width: '100%' }}>
              <TextField
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setEmail(e.currentTarget.value);
                  validateInput(e.currentTarget.value);
                }}
                id='email'
                label='Email'
                value={emailInput}
                type='email'
                required={true}
                style={{ width: '100%' }}
                autoFocus={true}
                error={error}
                helperText={errorMessage?.detail}
              />
            </Grid>

            <Grid style={{ width: '100%' }}>
              <TextField
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setPassword(e.currentTarget.value)
                }
                id='password'
                label='Password'
                value={password}
                type={passwordType}
                required={true}
                style={{ width: '100%' }}
                fullWidth={true}
                error={error}
                helperText={errorMessage?.detail}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton onClick={togglePassword}>
                        {passwordType === 'password' ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              ></TextField>
              <Link
                to='/forgotPassword'
                style={{
                  display: 'flex',
                  justifyContent: 'right',
                  fontSize: '14px',
                  margin: '5px'
                }}
              >
                Forgot Password?
              </Link>
            </Grid>

            <Grid>
              <Button
                type='submit'
                variant='contained'
                onClick={(e: React.FormEvent) => handleLogin(e)}
                startIcon={
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <LoginIcon />
                  </div>
                }
              >
                Log In
              </Button>
            </Grid>
            <Grid
              style={{
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <GoogleLoginButton
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: '10px 0 0 0',
                  fontSize: '14px',
                  maxWidth: '200px',
                  height: '40px'
                }}
                onClick={() => handleGoogleLogin()}
              >
                <span style={{ display: 'inline', width: '90%' }}>
                  Log in with Google
                </span>
              </GoogleLoginButton>
            </Grid>

            {/* <Grid
              style={{
                display: 'flex',
                justifyContent: 'center',
                margin: '10px 0 0 0'
              }}
            >
              <Button style={{ width: '80%' }} onClick={handleCleverLogin}>
                <img src={clever} style={{ width: '90%' }} alt='Clever Logo' />
              </Button>
            </Grid> */}

            <div style={{ color: '#1d1d1d', fontSize: 16 }}>
              <p>
                don't have an account? <Link to='/signup'>Sign Up</Link>
              </p>
            </div>
          </Stack>
        </form>
      </Box>
    </div>
  );
};
