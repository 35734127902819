import React, { useEffect, useState } from 'react';
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Button,
  Box,
  Stack,
  Grid,
  Typography
} from '@mui/material';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { AuthProvider, LessonProvider, theme } from '../providers';
import { makeStyles } from '@mui/styles';

interface Game {
  id: number;
  code: string;
  lesson_name: string;
  creator: number;
  completed: boolean;
  created_at: string;
  casel_type: string;
}

const useStyles = makeStyles({
  mainContent: {
    width: '80%',
    padding: '16px',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'auto',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '85%',
      margin: '0',
      minWidth: '1000px'
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '85%',
      margin: '0 0 0 8%'
    }
  },
  emptyBody: {
    textAlign: 'center',
    padding: '20px',
    fontStyle: 'italic'
  }
});

export const SurveyHistoryPage: React.FC = () => {
  const [data, setData] = useState({});
  const navigate = useNavigate();
  const classes = useStyles();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const gameData = await LessonProvider.getClassroomSurveys();
      setData(gameData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const hasMultiplayer = AuthProvider.hasMultiplayer();
  const userAuthenticated = AuthProvider.isAuthenticated();
  useEffect(() => {
    if (!userAuthenticated) {
      navigate('/');
    } else if (!hasMultiplayer) {
      navigate('/home');
    }
  }, [navigate, userAuthenticated, hasMultiplayer]);

  return (
    <Box className={classes.mainContent}>
      <Stack spacing={4} sx={{ width: '100%', justifyContent: 'center' }}>
        <Grid>
          <h4
            style={{
              color: '#3a5169',
              fontSize: 34,
              textDecoration: 'underline',
              margin: 0,
              textAlign: 'left'
            }}
          >
            Classroom Survey Results
          </h4>
        </Grid>
        <Grid>
          {data && Object.keys(data).length > 0 ? (
            Object.keys(data).map((key) => (
              <Grid sx={{ paddingBottom: 5 }}>
                <Grid sx={{ paddingBottom: 1 }}>
                  <Grid
                    item
                    sx={{
                      width: '50%',
                      display: 'inline-block',
                      textAlign: 'left'
                    }}
                  >
                    <Typography
                      style={{
                        color: '#3a5169',
                        fontSize: 24,
                        textAlign: 'left',
                        textDecoration: 'underline',
                        alignItems: 'center'
                      }}
                    >
                      {new Date(key).toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric'
                      })}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    spacing={2}
                    sx={{
                      display: 'inline-block',
                      width: '50%',
                      textAlign: 'right'
                    }}
                  >
                    <Link to={`/classroomSurvey/${key}`}>
                      <Button
                        variant='contained'
                        style={{
                          backgroundColor: '#3a5169',
                          fontSize: 16,
                          minWidth: '100px',
                          marginBottom: '10px'
                        }}
                      >
                        Classroom Survey Results
                      </Button>
                    </Link>
                  </Grid>
                </Grid>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead
                      sx={{ backgroundColor: '#3a5169', color: '#FFF' }}
                    >
                      <TableRow>
                        <TableCell
                          sx={{
                            fontSize: '18px',
                            fontWeight: '600',
                            color: '#FFF',
                            textAlign: 'center'
                          }}
                        >
                          Game Count
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: '18px',
                            fontWeight: '600',
                            color: '#FFF',
                            textAlign: 'center'
                          }}
                        >
                          Lesson Name
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: '18px',
                            fontWeight: '600',
                            color: '#FFF',
                            textAlign: 'center'
                          }}
                        >
                          Date Played
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: '18px',
                            fontWeight: '600',
                            color: '#FFF',
                            textAlign: 'center'
                          }}
                        >
                          CASEL Competency
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: '18px',
                            fontWeight: '600',
                            color: '#FFF',
                            textAlign: 'center'
                          }}
                        >
                          Survey Results
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.values(data[key as keyof object]).map(
                        (item: any, index) => (
                          <TableRow key={item.id} sx={{ fontSize: '16px' }}>
                            <TableCell sx={{ textAlign: 'center' }}>
                              {index + 1}
                            </TableCell>
                            <TableCell sx={{ textAlign: 'center' }}>
                              {item.lesson_name}
                            </TableCell>
                            <TableCell sx={{ textAlign: 'center' }}>
                              {new Date(item.created_at).toLocaleDateString(
                                'en-US',
                                {
                                  year: 'numeric',
                                  month: 'long',
                                  day: 'numeric'
                                }
                              )}
                            </TableCell>
                            <TableCell sx={{ textAlign: 'center' }}>
                              {item.casel_type}
                            </TableCell>
                            <TableCell sx={{ textAlign: 'center' }}>
                              <Link to={`/survey/${item.code}`}>
                                <Button sx={{ color: '#3a5169' }}>
                                  {' '}
                                  Match Survey Results
                                </Button>
                              </Link>
                            </TableCell>
                          </TableRow>
                        )
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            ))
          ) : (
            <h4
              style={{
                fontSize: 20,
                margin: 0,
                textAlign: 'left',
                paddingBottom: 4
              }}
            >
              {' '}
              Game history loading or no data available yet. Please try a game
              first!
            </h4>
          )}
        </Grid>
      </Stack>
    </Box>
  );
};
