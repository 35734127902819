// export const BASE_URL = 'http://localhost:{{cookiecutter.port}}';
// export const BACKEND_URL = 'http://localhost:{{cookiecutter.port}}/api/v1';

export const apiUrl =
  process.env.REACT_APP_ENV === 'production'
    ? process.env.REACT_APP_PROD_API_URL!
    : process.env.REACT_APP_DEV_API_URL!;

export const gameUrl =
  process.env.REACT_APP_ENV === 'production'
    ? process.env.REACT_APP_PROD_GAME_URL!
    : process.env.REACT_APP_DEV_GAME_URL!;

export const googleSSOClient =
  process.env.REACT_APP_ENV === 'production'
    ? process.env.REACT_APP_PROD_GOOGLE_SSO_CLIENT!
    : process.env.REACT_APP_DEV_GOOGLE_SSO_CLIENT!;

export const baseCookieDomain =
  process.env.REACT_APP_DEV_API_URL === 'http://localhost:8888'
    ? 'localhost'
    : '.lifelessonsed.com';

export const Units = {
  '1': {
    id: '1',
    name: 'Naming Emotions',
    objectives: [
      'Be presented with the “six basic emotions” including joy, sadness, disgust, anger, surprise and fear.',
      'Practice labeling the six basic emotions using visual supports.',
      'Watch teacher modeling of the verbal and nonverbal expression of basic emotions.',
      'Practice modeling the verbal and nonverbal expression of basic emotions.',
      'Practice emotional expression modeling with peers in pairs.'
    ],
    description:
      'This Unit is designed to introduce students to Paul Ekman’s “six basic emotions” including joy, sadness, disgust, anger, surprise and fear. Students will have an opportunity to practice identifying and labeling emotions. This Unit will prepare students to begin to understand these emotions in context through various scenarios.'
  },
  '2': {
    id: '2',
    name: 'Understanding Our Emotions',
    objectives: [
      'Practice using “I feel” statements to express their emotions.',
      'Have an opportunity to practice sharing and listening to peers express their emotions.',
      'Practice recognizing the psychical cues of emotion through a creative expression exercise.',
      'Engage in a teacher-guided exercise to link emotions to various social situations.',
      'Engage in a self-reflective exercise to better understand the emotions they experience.'
    ],
    description:
      'This Unit is designed to help students to better understand their own emotions. Students will have an opportunity to practice identifying and labeling emotions that they experience, as well as strategies for communicating those emotions. This Unit will help students practice the skills to begin to recognize emotions in others, setting the stage for the development of empathy.'
  },
  '3': {
    id: '3',
    name: 'Challenging Scenarios and Emotions in Context',
    objectives: [
      'Play an Outpost lesson, led by the teacher to meet a new character and learn about his or her SEL challenge.',
      'Practice turn-taking and flexible thinking in a group activity to explain the challenge and its impact on the character’s emotions.',
      'Engage in problem solving by participating in an all-class discussion.',
      'Exercise perspective taking skills through a teacher guided reflective exercise.',
      'Practice collaborative problem solving in a peer-group creative activity.'
    ],
    description:
      'This Unit is designed to help students to better understand how various scenarios, contexts and situations may elicit different kinds of emotions. Students will interact with the Outpost™ lessons and have an opportunity to practice identifying and discussing challenging scenarios that one may encounter in a school setting. This Unit will help students practice the skills to begin to recognize challenging situations and their impact on emotions in others, as well as position them to begin to consider strategies for dealing with challenging emotions.'
  },
  '4': {
    id: '4',
    name: 'Emotion Regulation Strategies and Empathy',
    objectives: [
      'Learn effective breathing strategies to jump start the emotion regulation process.',
      'Practice using self-talk to manage challenging emotions.',
      'Practice perspective-taking through their engagement in a teacher-led metacognition-building activity.',
      'Engage with peers to understand each other’s emotions.',
      "Practice recognizing and responding to each other's emotions empathetically."
    ],
    description:
      'This Unit is designed to provide students with opportunities to kick-start the emotion regulation process by calming themselves using breathing techniques, and guiding their thoughts and behavior with the use of self-talk. This Unit is also designed to support students’ perspective-taking skills as they engage with one another to practice recognizing others emotions and providing an empathetic response. Students will have opportunities to discuss and exercise the emotion-regulatory skills that they can apply to various challenges, and begin to build resilience that will lay the foundation for strong self-regulation into adulthood.'
  },
  '5': {
    id: '5',
    name: 'Putting It All Together',
    objectives: [
      'Self-reflect on their understanding of emotions in themselves and others.',
      'Exercise empathy skills in a peer-to-peer activity.',
      'Engage in creative thinking while discussing emotion-regulation strategies.'
    ],
    description:
      'Congratulations! You have made it to Unit 5-- now it is time to put it all together. This Unit is designed to reinforce the skills students exercised in Unit 1-4. This unit will give students the chance to practice identifying and labeling emotions, describing how the emotions are experienced in their bodies, tying emotions to events and situations, and recognizing emotions in others.'
  }
};

export const Activities = {
  '1': [
    {
      id: '0',
      unitId: '1',
      title: 'Mirroring',
      background:
        'This activity involves emotion mirroring, which aligns with mirror neuron theory. Mirror neurons are thought to facilitate understanding and imitation of others’ actions and emotions. Research by Iacoboni (2005) suggests that mirroring facial expressions activates these mirror neurons, aiding in social and emotional learning. Mirror neurons are neurons that fire both when an individual performs an action and when they observe the same action performed by another. Iacobonis research (2005) suggests that mirroring facial expressions activates these mirror neurons, aiding in social and emotional learning by facilitating the understanding and imitation of others emotions and actions.',
      icon: 'add',
      time: '25 - 35',
      objective: 'To practice emotional expression modeling with peers.',
      materials: ['No materials required'],
      steps: [
        {
          title: 'Introduction (5 minutes)',
          bullets: [
            'Review the six basic emotions and their expressions (joy, sadness, disgust, anger, surprise, and fear).'
          ]
        },
        {
          title: 'Modeling (5 minutes)',
          bullets: [
            'Demonstrate exaggerated facial expressions for each emotion.',
            'Encourage students to mimic your expressions.'
          ]
        },
        {
          title: 'Mirror Game (15-20 minutes)',
          bullets: [
            'Pair students up facing each other.',
            'One student makes a facial expression of an emotion while the other mirrors it.',
            'Have students switch roles after a short time (a few minutes should suffice).'
          ]
        }
      ]
    },
    {
      id: '1',
      unitId: '1',
      title: 'Drawing Relay',
      background:
        'This activity combines kinesthetic learning with emotional expression. Research by Malchiodi (2012) suggests that art-based activities help children express emotions non-verbally, enhancing emotional intelligence and self-awareness through creative processes. Malchiodi’s research (2012) focuses on art therapy and its impact on emotional expression. Art-based activities like drawing help children express emotions non-verbally, enhancing emotional intelligence and self-awareness through creative processes.',
      icon: 'draw',
      time: ' 30 - 35',
      objective:
        'Practice modeling basic emotions through drawing and nonverbal expression.',
      materials: ['Large sheets of paper', 'A set of markers', 'A timer'],
      steps: [
        {
          title: 'Introduction (5 minutes)',
          bullets: [
            'Remind students about the six basic emotions and their expressions (joy, sadness, disgust, anger, surprise, and fear).'
          ]
        },
        {
          title: 'Preparation (10 minutes)',
          bullets: [
            'Divide students into teams and provide each team with a large sheet of paper and markers.'
          ]
        },
        {
          title: 'Drawing Relay (15-20 minutes)',
          bullets: [
            'The first student from each team runs to the paper, draws an expression representing an emotion, then runs back to tag the next teammate.',
            'The next student continues the drawing based on the previous one, adding to the emotion expression.',
            'Repeat until all team members have contributed to the drawing.'
          ]
        }
      ]
    },
    {
      id: '2',
      unitId: '1',
      title: 'Collage Creation',
      background:
        'This activity promotes artistic expression, tapping into multiple intelligences as proposed by Howard Gardner’s theory of multiple intelligences. By engaging in creative tasks like collage making, children enhance their emotional intelligence and self-expression abilities (Gardner, 1983). Research by Milovanov et al. (2016) indicates that engaging in artistic activities stimulates neural networks associated with emotional processing and creative thinking in children.',
      icon: 'cut',
      time: '40 - 45',
      objective: 'Practice visual expression of basic emotions through art.',
      materials: [
        'A few magazines',
        'A few scissors',
        'Bottles of glue',
        'large sheets of paper',
        'A set of markers'
      ],
      steps: [
        {
          title: 'Introduction (5 minutes)',
          bullets: [
            'Recap the six basic emotions and briefly discuss how they can be expressed visually (joy, sadness, disgust, anger, surprise, and fear).'
          ]
        },
        {
          title: 'Collage Creation (30-35 minutes)',
          bullets: [
            'Provide students with magazines, scissors, and glue.',
            'Ask them to find and cut out images that represent each of the six basic emotions.',
            'Have them create a collage using these images on a large sheet of paper.',
            'Encourage labeling or drawing expressions if magazines don’t have suitable images.'
          ]
        }
      ]
    }
  ],
  '2': [
    {
      id: '0',
      unitId: '2',
      title: '"I Feel" Statements',
      background:
        'The concept of using "I feel" statements aligns with the socio-emotional learning framework proposed by CASEL (Collaborative for Academic, Social, and Emotional Learning). According to research incorporated by CASEL, explicitly using "I feel" statements fosters emotional intelligence and effective communication.',
      icon: 'toy',
      objective: 'Practice using "I feel" statements to express emotions.',
      time: '20 - 25',
      materials: ['No materials required'],
      steps: [
        {
          title: 'Introduction (5 minutes)',
          bullets: [
            'Explain the importance of recognizing and expressing emotions using "I feel" statements.'
          ]
        },
        {
          title: 'Modeling (5 minutes)',
          bullets: [
            'Demonstrate a few "I feel" statements using relatable scenarios (We recommend using some SEL scenarios from Outpost!)'
          ]
        },
        {
          title: 'Practice Session (10-15 minutes)',
          bullets: [
            'Have students pair up and take turns expressing emotions using "I feel" statements.',
            'Encourage active listening and supportive responses.'
          ]
        }
      ]
    },
    {
      id: '1',
      unitId: '2',
      title: 'Sharing Circle',
      background:
        'This activity aligns with theories of social learning and emotional understanding. According to Bandura’s Social Learning Theory, learning occurs through observation and modeling. Bandura’s Social Learning Theory suggests that learning occurs through observation and modeling. Encouraging students to share and listen to peers expressing emotions fosters empathy and emotional recognition, aligning with the idea of observational learning influencing behavior and emotional understanding. Sharing and listening to peers express their emotions fosters empathy and emotional recognition.',
      icon: 'bulb',
      objective: 'Practice using "I feel" statements to express emotions.',
      time: '20 - 25',
      materials: ['No materials required'],
      steps: [
        {
          title: 'Introduction (5 minutes)',
          bullets: [
            'Explain the importance of recognizing and expressing emotions using "I feel" statements.'
          ]
        },
        {
          title: 'Modeling (5 minutes)',
          bullets: [
            'Demonstrate a few "I feel" statements using relatable scenarios (We recommend using some SEL scenarios from Outpost!)'
          ]
        },
        {
          title: 'Practice Session (10-15 minutes)',
          bullets: [
            'Have students pair up and take turns expressing emotions using "I feel" statements.',
            'Encourage active listening and supportive responses.'
          ]
        }
      ]
    },
    {
      id: '2',
      unitId: '2',
      title: 'Physical Expression',
      background:
        'This activity uses the concept of engaging in creative expression to depict emotions which aligns with Howard Gardner’s Theory of Multiple Intelligences. Gardner’s theory proposes that individuals possess various forms of intelligence, including visual-spatial intelligence, which involves expressing oneself through artistic means. Engaging in creative activities to express emotions enhances emotional intelligence and self-awareness.',
      icon: 'agri',
      objective:
        'Recognize physical cues of emotions through a creative expression exercise.',
      time: '40 - 45',
      materials: ['Sheets of paper', 'A set of coloring pencils/markers'],
      steps: [
        {
          title: 'Introduction (10 minutes)',
          bullets: [
            'Discuss the physical cues associated with different emotions (e.g., facial expressions, body language).'
          ]
        },
        {
          title: 'Creative Expression Time (30-35 minutes)',
          bullets: [
            'Ask students to choose an emotion and represent it through artwork without using words.',
            'Encourage them to focus on depicting the physical cues associated with that emotion.'
          ]
        }
      ]
    }
  ],
  '3': [
    {
      id: '0',
      unitId: '3',
      title: 'Self-Reflective Journaling',
      background:
        'This activity aligns with theories of metacognition and self-reflection. According to Flavell’s Theory of Metacognition, self-awareness and reflection foster understanding and are essential components of metacognitive processes. Reflecting on personal experience aids in better understanding oneself and one’s emotions.',
      icon: 'brain',
      objective:
        'Engage in a self-reflective journaling exercise to better understand emotions during a recent personal experience.',
      time: '35 - 45',
      materials: ['A notebook', 'A pen'],
      steps: [
        {
          title: 'Introduction (5 minutes)',
          bullets: [
            'Discuss the importance of self-reflection in understanding one’s emotions.'
          ]
        },
        {
          title: 'Journaling (25-30 minutes)',
          bullets: [
            'Provide time for students to reflect in their notebooks on emotions they experienced in a recent personal situation.',
            'Encourage them to consider why they felt a particular emotion and how they responded.'
          ]
        },
        {
          title: 'Pair and Share (5 - 10 minutes)',
          bullets: [
            'Provide time for students to share their story with a partner.',
            'Encourage active listening with each pair of students.'
          ]
        }
      ]
    },
    {
      id: '1',
      unitId: '3',
      title: 'Social Situation Linking',
      background:
        'This activity aligns with theories of experiential learning. Experiencing emotions in different social situations enhances emotional understanding and recognition. According to Kolb’s Experiential Learning Theory, reflective observation and concrete experiences aid in learning.',
      icon: 'game',
      objective:
        'Practice linking emotions to social situations by engaging in a teacher-guided Outpost lesson to link emotions to a given social situation.',
      time: '30 - 35',
      materials: ['An Outpost Lesson'],
      steps: [
        {
          title: 'Introduction (5 minutes)',
          bullets: [
            'Explain the connection between emotions and social situations.'
          ]
        },
        {
          title: 'Outpost Lesson Exploration (25-35 minutes)',
          bullets: [
            'Play an Outpost Lesson with the class.',
            'Discuss and guide students in identifying and linking emotions they might feel in the given lesson and corresponding social situation.'
          ]
        }
      ]
    },
    {
      id: '2',
      unitId: '3',
      title: 'Constructive Storytelling',
      background:
        'Storytelling promotes narrative thinking and emotional processing. This activity aligns with the theories of narrative psychology, emphasizing the use of stories to make sense of experiences and emotions. Engaging with stories stimulates both cognitive and emotional processes. The cognitive aspect involves understanding plotlines, characters, and contexts, while the emotional aspect involves empathizing with characters’ feelings, exploring different emotional perspectives, and processing one’s own emotions resulting from the narrative.',
      icon: 'page',
      objective:
        'Engage in storytelling to practice resolving emotions in challenging scenarios.',
      time: '40 - 45',
      materials: [
        'Sheets of paper',
        'A set of coloring pencils/markers',
        'A storytelling template - beginning, middle, end (optional)'
      ],
      steps: [
        {
          title: 'Introduction (10 minutes)',
          bullets: [
            'Explain the concept of using storytelling as a tool for emotional resolution in challenging situations.'
          ]
        },
        {
          title: 'Story Creation (30-35 minutes)',
          bullets: [
            'Optional: Write and review a template with spaces for the beginning, middle, and end of a story on whiteboard.',
            'Task students to create stories depicting how characters in challenging scenarios can navigate and resolve their emotions.'
          ]
        }
      ]
    }
  ],
  '4': [
    {
      id: '0',
      unitId: '4',
      title: 'Self-Talk',
      background:
        'Self-talk refers to the ongoing internal dialogue or the way individuals talk to themselves in their minds. Using self-talk aligns with cognitive-behavioral strategies that aid in managing emotions. Cognitive restructuring techniques, supported by cognitive-behavioral therapy research, involve replacing negative thoughts with positive affirmations, enhancing emotional regulation.',
      icon: 'chat',
      objective: 'Practice using self-talk to manage challenging emotions.',
      time: '35 - 45',
      materials: ['Sheets of paper', 'A pen/set of markers'],
      steps: [
        {
          title: 'Introduction (5 minutes)',
          bullets: [
            'Explain the concept of self-talk and its role in managing emotions.'
          ]
        },
        {
          title: 'Self-Talk Exercise (20-25 minutes)',
          bullets: [
            'Encourage students to first write down two challenging emotions they face and then what positive self-talk statements they use to manage those emotions.'
          ]
        },
        {
          title: 'Group Exercise (10-15 minutes)',
          bullets: [
            'Divide class into groups and have each group member share their chosen challenging emotions and corresponding positive affirmations.'
          ]
        }
      ]
    },
    {
      id: '1',
      unitId: '4',
      title: 'Perspective-Taking',
      background:
        'Metacognition involves understanding one’s thinking processes, including perspective-taking. Activities that promote metacognition align with Vygotsky’s sociocultural theory, emphasizing the role of social interactions in cognitive development.',
      icon: 'face',
      objective:
        'Practice perspective-taking through engaging in a teacher-guided Outpost lesson to build metacognition with students.',
      time: '30 - 40',
      materials: ['An Outpost Lesson'],
      steps: [
        {
          title: 'Introduction (5 minutes)',
          bullets: [
            'Discuss the importance of understanding differing perspectives in managing emotions.'
          ]
        },
        {
          title: 'Outpost Lesson Exploration (25-35 minutes)',
          bullets: [
            'Play an Outpost Lesson with the class.',
            'Highlight various perspectives from students in the class and how they are unique.',
            'Encourage students to consider different perspectives and discuss how emotions might vary among the characters in the given situation from the lesson.'
          ]
        }
      ]
    },
    {
      id: '2',
      unitId: '4',
      title: 'Deep Breathing',
      background:
        'Teaching breathing techniques aligns with mindfulness practices that help regulate emotions by promoting present-moment awareness. Mindfulness practices, supported by research in psychology, aid in reducing stress and enhancing emotional regulation.  Studies have shown that regular practice of breathing techniques can decrease levels of cortisol (a stress hormone) and increase activity in brain regions associated with emotional regulation, leading to improved well-being and decreased anxiety.',
      icon: 'air',
      objective:
        'Learn effective breathing strategies to initiate emotion regulation.',
      time: '10 - 15',
      materials: ['No materials required'],
      steps: [
        {
          title: 'Introduction (5 minutes)',
          bullets: [
            'Explain the importance of breathing in regulating emotions and reducing stress.'
          ]
        },
        {
          title: 'Guide students through deep belly breathing (5-10 minutes)',
          bullets: [
            'Have students find a comfortable seated position.',
            'Tell them to place one hand on their chest and the other on their abdomen, just below their rib cage.',
            'Tell them to breathe in slowly and deeply through their nose.',
            'As they inhale, tell them to focus on expanding their abdomen. Their hand on their abdomen should rise while their hand on their chest remains relatively still.',
            'Tell them to exhale slowly through their mouth or nose, allowing their abdomen to contract slowly.',
            'Ask them to feel if their hand on their abdomen lowered as they exhaled.',
            'Have them repeat this exercise 2-3 times and ask them how they feel before vs. after.'
          ]
        }
      ]
    }
  ],
  '5': [
    {
      id: '0',
      unitId: '5',
      title: 'Emotion Regulation Toolbox',
      background:
        'Creating an emotion regulation toolbox empowers students with personal strategies to manage emotions effectively. This activity aligns with some earlier self-regulation theories including the Control Theory (Carver & Scheier), the Process Model of Emotion Regulation (Gross), and the Self-Determination Theory (Deci & Ryan) that emphasize providing individuals with tools and strategies to regulate their emotions.',
      icon: 'toolbox',
      objective: 'Develop personalized emotion regulation toolboxes.',
      time: '40 - 50',
      materials: ['A set of index cards', 'A pen/set of markers'],
      steps: [
        {
          title: 'Introduction (5 minutes)',
          bullets: [
            'Explain the concept of an emotion regulation toolbox and its purpose. For instance, someone’s emotion regulation toolbox might include strategies like mindfulness meditation for calming the mind, deep breathing techniques to reduce anxiety, positive affirmations or self-talk to boost self-confidence, engaging in hobbies or physical activities for stress relief, or seeking social support through talking to a trusted friend or family member.'
          ]
        },
        {
          title: 'Toolbox Creation (25-30 minutes)',
          bullets: [
            'Provide craft supplies and index cards.',
            'Encourage students to create individualized cards with their various emotion regulation strategies (deep breathing, positive affirmations, etc.) for their toolbox.'
          ]
        },
        {
          title: 'Group Exercise (10-15 minutes)',
          bullets: [
            'Divide class into groups and have each group member share one of their strategies and how it helps them manage their emotions.'
          ]
        }
      ]
    },
    {
      id: '1',
      unitId: '5',
      title: 'Role Reversal',
      background:
        'Role reversal activities promote perspective-taking and empathy by immersing individuals in others’ experiences. This aligns with Bandura’s Social Learning Theory, emphasizing learning through observation and role modeling.',
      icon: 'reverse',
      objective:
        'Exercise perspective-taking skills in a peer-to-peer activity following an Outpost Lesson.',
      time: '40 - 45',
      materials: ['An Outpost Lesson involving two or more characters'],
      steps: [
        {
          title: 'Introduction (5 minutes)',
          bullets: [
            'Discuss the importance of perspective-taking and empathy in understanding emotions.'
          ]
        },
        {
          title: 'Role Reversal Activity From Outpost Lesson (25-30 minutes)',
          bullets: [
            'Play an Outpost Lesson with the class.',
            'Split the students into groups, and have them take turns assuming different characters from the lesson, and discuss how each character might feel and react given the situation in the lesson.',
            'Have the students in each group switch the character that they are assuming until all students have been each character from the lesson.'
          ]
        },
        {
          title: 'Role Reversal Discussion (10 mintues)',
          bullets: [
            'Bring the class together and discuss what they learned together.'
          ]
        }
      ]
    },
    {
      id: '2',
      unitId: '5',
      title: 'Appreciation Board',
      background:
        'Expressing gratitude for emotions fosters emotional intelligence. This activity aligns with positive psychology theories, like Character Strengths and Virtues (Peterson & Seligman), which emphasizes the importance of cultivating virtues and character strengths for overall well-being. Appreciating emotions as a part of personal growth aligns with the virtue of wisdom and the strength of emotional intelligence. Recognizing emotions, even challenging ones, can be seen as a part of developing emotional wisdom.',
      icon: 'board',
      objective:
        'Cultivate gratitude and understanding for different emotions.',
      time: '45 - 50',
      materials: [
        'A poster board',
        'A set of markers',
        'A pack of sticky notes'
      ],
      steps: [
        {
          title: 'Introduction (5 minutes)',
          bullets: [
            'Discuss the importance of appreciating and understanding emotions.'
          ]
        },
        {
          title: 'Appreciation Board Creation (40-45 minutes)',
          bullets: [
            'Students create an emotion appreciation board, dedicating sections to various emotions.',
            'Each section includes the name of an emotion, its description, and sticky notes where students can express appreciation or positive experiences related to that emotion.'
          ]
        }
      ]
    }
  ]
};
